import { logger } from "../../../../utils/logger";
import { toggleAlertsOff } from "../../../../utils/toggleAlertsOff";
import { Tag, PostableTag, PatchableTag } from "../../../../model/Classes/Tag";
import { Nullable, StateHandler } from "../../../../model/Utilities/Types";
import StructuredResponse from "../../../../model/Classes/StructuredResponse";
import { parseISOString, decodeAndMakeDate, pad } from "../../../../utils/dates";
import { timer } from "../../../../utils/timer";

import { enableTag, disableTag, editTag, deleteTag } from "../../../../services/userService";

import { getUserTags } from "../../../../services/userService";
import { getRecentTagScanEvent } from "../../../../services/userService";
import { postTag } from "../../../../services/userService";

/**
 * Helper function for checking if an ISO-String date is still valid or not. The function does so
 * by creating a new Javascript Date object and converting it into a ISO-String. The function then
 * compares the given date parameter with the newly created date. If given date > newly created date
 * (i.e. the given date is AFTER the newly created date), set the return value to contained a string
 * for the given date in local time. Otherwise, do the same but add a "(outdated)" string in the end
 * to notify the user that the tag is expired.
 * @param {*} date given date in ISO-String format
 * @returns the date in local time (and "outdated" if the tag is expired)
 */
export const checkStillValidTag = (date: string) => {
  const d = new Date().toISOString(); //Create a new Javascript Date object and convert it to an ISO-String
  let res;
  d > date //If true, the given date means the tag is expired.
    ? (res = <span className="outdated">{parseISOString(date).toLocaleString("en-FI")} (outdated)</span>)
    : (res = parseISOString(date).toLocaleString("en-FI"));

  //Return the string
  return res;
};

/**
 * Asynchronous helper function for getting all the tags
 */
export const getTags = async (setAllTags: StateHandler<Tag[]>, setShowView: StateHandler<number>) => {
  //Additionally, fetch all the tags for the user again to get the updated data by calling
  //the service function getOwnTags()
  const res = await getUserTags();

  if (res.success) {
    //Check if the result came back as successful
    //Success! Update the allTags state to contain the updated data

    sortTags(await res.data, setAllTags);
    setShowView(1); //Set the showView state to 1 to show the UserSettingsTags tab
  } else {
    logger(res.data);
    setShowView(0); //Set the showView state to 0 notifying the user that an error occured
  }
};

export const sortTags = (res: Tag[], setAllTags: StateHandler<Tag[]>) => {
  //Sort response so that 'App' tag is always first on the list. Not functionally necessary but it's more consistent
  const sortAppFirst = res.filter((t) => t.nickname === "App").concat(res.filter((t) => t.nickname !== "App"));
  setAllTags(sortAppFirst);
};

/**
 * Helper function for toggling the edit view on. If the parameter tag is provided, the code
 * update the tagToEdit state to contain the tag and possibly the editNickname, editValidUntilDate,
 * and editValidUntilTime states (if the data for those states exist in the tag data). Otherwise,
 * it resets the tagToEdit state.
 *
 * Finally, the code switches between the edit mode and normal mode.
 */
export const toggleEdit = (
  tag: Nullable<Tag>,
  setTagToEdit: StateHandler<Nullable<Tag>>,
  setEditNickname: StateHandler<string>,
  setEditValidUntilDate: StateHandler<string>,
  setEditValidUntilTime: StateHandler<string>,
  setEditMode: StateHandler<boolean>,
  editMode: boolean,
  setEditNicknameAlreadyExistsAlert: StateHandler<boolean>,
  setInvalidEditNicknameAlert: StateHandler<boolean>,
  setNoEmptyEditNicknameAlert: StateHandler<boolean>
) => {
  //Check if the tag was provided
  if (tag !== null) {
    //A tag was provided, update the tagToEdit state to contain the tag
    setTagToEdit(tag);
    tag.nickname ? setEditNickname(tag.nickname) : setEditNickname(""); //If the tag has a nickname, update the editNickname state. Otherwise, reset the state
    if (tag.valid_until) {
      //Check if the tag contains valid until
      //It did. Since the data is in the ISO-String format, we need to parse it to a Javascript Date object
      //(easier to handle than a ISO-String)
      const valid = parseISOString(tag.valid_until);

      //Get the month, date, hour, and minute from the Date object and pad the results (reason why it's
      //needed can be found from the comments for the pad function)
      const month = pad(valid.getMonth());
      const date = pad(valid.getDate());
      const hour = pad(valid.getHours());
      const minute = pad(valid.getMinutes());

      //Update the correct states containing the valid until date and time. The strings are in
      //html date input format (YYYY-MM-DD and HH:MM)
      setEditValidUntilDate(`${valid.getFullYear()}-${month}-${date}`);
      setEditValidUntilTime(`${hour}:${minute}`);
    } else {
      //The tag didn't have a valid until date, set the states to empty strings
      setEditValidUntilDate("");
      setEditValidUntilTime("");
    }
  } else {
    //No tag provided as a parameter, reset the tagToEdit state
    setNoEmptyEditNicknameAlert(false);
    setInvalidEditNicknameAlert(false);
    setEditNicknameAlreadyExistsAlert(false);
    setTagToEdit(null);
  }

  //Finally, switch the mode
  setEditMode(!editMode);
};

export const toggleEditOverload = (
  tag: Tag,
  setTagToEdit: StateHandler<Nullable<Tag>>,
  setEditNickname: StateHandler<string>,
  setEditValidUntilDate: StateHandler<string>,
  setEditValidUntilTime: StateHandler<string>,
  setEditMode: StateHandler<boolean>,
  editMode: boolean
) => {
  //Check if the tag was provided
  if (tag) {
    //A tag was provided, update the tagToEdit state to contain the tag
    setTagToEdit(tag);
    tag.nickname ? setEditNickname(tag.nickname) : setEditNickname(""); //If the tag has a nickname, update the editNickname state. Otherwise, reset the state
    if (tag.valid_until) {
      //Check if the tag contains valid until
      //It did. Since the data is in the ISO-String format, we need to parse it to a Javascript Date object
      //(easier to handle than a ISO-String)
      const valid = parseISOString(tag.valid_until);

      //Get the month, date, hour, and minute from the Date object and pad the results (reason why it's
      //needed can be found from the comments for the pad function)
      const month = pad(valid.getMonth() + 1);
      const date = pad(valid.getDate());
      const hour = pad(valid.getHours());
      const minute = pad(valid.getMinutes());

      //Update the correct states containing the valid until date and time. The strings are in
      //html date input format (YYYY-MM-DD and HH:MM)
      setEditValidUntilDate(`${valid.getFullYear()}-${month}-${date}`);
      setEditValidUntilTime(`${hour}:${minute}`);
    } else {
      //The tag didn't have a valid until date, set the states to empty strings
      setEditValidUntilDate("");
      setEditValidUntilTime("");
    }
  }

  //Finally, switch the mode
  setEditMode(!editMode);
};

/**
 * Helper function for handling the functionality for disabling or enabling a tag
 * @param {*} tag tag to enable or disable
 * @param {*} toggle flag used to know if the code should activate or disable the tag
 */
export const handleDisableOrEnable = async (
  tag: Tag,
  toggle: number,
  setSuccessfullEnableAlert: StateHandler<boolean>,
  setSuccessfullDisableAlert: StateHandler<boolean>,
  setErrorEnableAlert: StateHandler<boolean>,
  setErrorDisableAlert: StateHandler<boolean>,
  setAllTags: StateHandler<Tag[]>,
  setShowView: StateHandler<number>
) => {
  //If the toggle parameter is true, call the enableTag() service function to enable the tag
  //Otherwose, call the disabledTag() service function to disable the tag
  let changeRes: StructuredResponse<Tag | null>;
  toggle ? (changeRes = await enableTag(tag.id)) : (changeRes = await disableTag(tag.id));

  if (changeRes.success) {
    //Check if the request came back as successful
    //Success! Now, trigger an alert to notify the user that the activating/disabling was successful.
    timer(toggle ? setSuccessfullEnableAlert : setSuccessfullDisableAlert);
    await getTags(setAllTags, setShowView); //Fetch all the tags again to update the tags
  } else {
    //Errors occured when trying to delete the tag
    logger(changeRes.data);

    //Trigger an error alert to notify the user that the activating/enabling the tag failed
    timer(toggle ? setErrorEnableAlert : setErrorDisableAlert);

    //Fetch the tags again. Even though no data changed, the active/disabled buttons is wrong
    //for the tag the user tried to do the action for. Thus, the safest way to mitigate this
    //is to fetch all the tags again and update the tags based on the data.
    await getTags(setAllTags, setShowView);
  }
};

/**
 * Asynchronous helper function for handling the submission when editting a tag. The function creates a data
 * object containing the tag id (cannot be changed), new nickname, and new valid until. Then it calls the
 * editTag() service function to send the data to the backend. If it's successful, it triggers a success
 * alert to show, and fetches all the tags again to get the updated information. If it fails, log the error
 * and trigger an error alert.
 *
 * Finally, the function closes the edit popup.
 */
export const handleTagEdit = async (
  allTags: Tag[],
  editValidUntilDate: string,
  editValidUntilTime: string,
  tagToEdit: Tag,
  editNickname: string,
  setSuccessfullEditAlert: StateHandler<boolean>,
  setFailedEditAlert: StateHandler<boolean>,
  setEditMode: StateHandler<boolean>,
  setAllTags: StateHandler<Tag[]>,
  setShowView: StateHandler<number>,
  setNoEmptyEditNicknameAlert: StateHandler<boolean>,
  setInvalidEditNicknameAlert: StateHandler<boolean>,
  setEditNicknameAlreadyExistsAlert: StateHandler<boolean>
) => {
  toggleAlertsOff([setNoEmptyEditNicknameAlert, setInvalidEditNicknameAlert, setEditNicknameAlreadyExistsAlert]);

  if (!allTags.every((tag) => tag.nickname !== editNickname) && editNickname !== tagToEdit.nickname) {
    setEditNicknameAlreadyExistsAlert(true);
    return;
  } else if (editNickname.trim().length === 0) {
    setNoEmptyEditNicknameAlert(true);
    return;
  } else if (editNickname.length > 128) {
    setInvalidEditNicknameAlert(true);
    return;
  }

  //The value for the valid until data, initially null (null = no valid until date)
  let validUntil: Nullable<string> = null;

  //If the user inserted a valid until date AND time, change the validUntil variable to contain the
  //data in ISO-String format (needed since it's stored in this format in the database). This is
  //done by first decoding the fields from html date input format to a Javascript Date object. After
  //that, we simply call the toISOString() function for the Date object to convert it into an ISO-String.
  if (editValidUntilDate !== "") {
    validUntil = decodeAndMakeDate(editValidUntilDate, "23:59:59").toISOString();
  }

  const id: Tag["id"] = tagToEdit.id!;

  const data: PatchableTag = {
    valid_until: validUntil,
    nickname: editNickname,
  };

  //Call the editTag() service function to send the changed data to the backend
  const editTagResponse = await editTag(id, data);

  if (editTagResponse.success) {
    //Check if the result came back as successful
    //Success! Now, trigger the alert to notify the user that the edit was successful.
    timer(setSuccessfullEditAlert);
    await getTags(setAllTags, setShowView); //Fetch all the tags again to update the tags
  } else {
    //Errors occured when trying to edit the tag
    logger(editTagResponse.data);

    //Trigger an error alert to notify the user that the edit failed
    timer(setFailedEditAlert);
  }

  //Finally, close the edit popup
  setEditMode(false);
};

/**
 * Asynchronous helper function for deleting a tag. Initially, the function creates a data object
 * containing the tag id, and calls the deleteTag() service function. If it's successful, it
 * triggers a success alert to show, and fetches all the tags again to get the updated information.
 * If it fails, log the error and trigger an error alert.
 * @param {*} tag tag to delete
 */
export const handleTagDelete = async (
  tag: Tag,
  setSuccessfullDeleteAlert: StateHandler<boolean>,
  setFailedDeleteAlert: StateHandler<boolean>,
  setAllTags: StateHandler<Tag[]>,
  setShowView: StateHandler<number>
) => {
  //Call the deleteTag() service function to send the data to the backend
  const deleteRes = await deleteTag(tag.id);

  if (deleteRes.success) {
    //Check if the result came back as successful
    //Success! Now, trigger the alert to notify the user that the delete was successful.
    timer(setSuccessfullDeleteAlert);

    await getTags(setAllTags, setShowView); //Fetch all the tags again to update the tags
  } else {
    //Errors occured when trying to delete the tag
    logger(deleteRes.data);

    //Trigger an error alert to notify the user that the delete failed
    timer(setFailedDeleteAlert);
  }
};

/**
 * Helper function to validate the input fields. The function checks for multiple criteria, and if a criteria fails
 * it sets the success variable to false and toggles the respective alert on.
 * This is done instead of instantly returning false, because then all the criteria are checked instead of the
 * function stopping once the first violation occured.
 * @returns true if the validation passes, false otherwise
 */
export const validate = (
  allTags: Tag[],
  newTagNickname: string,
  setInvalidNicknameAlert: StateHandler<boolean>,
  setNoEmptyNicknameAlert: StateHandler<boolean>,
  setNicknameAlreadyExistsAlert: StateHandler<boolean>
) => {
  //Return value
  let success = true;
  if (!allTags.every((tag) => tag.nickname !== newTagNickname)) {
    setNicknameAlreadyExistsAlert(true);
    success = false;
  } else if (newTagNickname.trim().length === 0) {
    setNoEmptyNicknameAlert(true);
    success = false;
  } else if (newTagNickname.length > 128) {
    setInvalidNicknameAlert(true);
    success = false;
  }
  return success;
};

/**
 * Asynchronous helper function for adding a tag when the user chose either the "Add tag ID manually" or
 * "Generate tag ID" options.
 *
 * @param validUntil the date the tag is valid until
 */
export const handlePostTag = async (
  tagScanEventId: number | null,
  newTagNickname: string,
  validUntil: string,
  newDisabled: string,
  setNewlyAddedTagId: StateHandler<Nullable<number>>,
  setSuccessfullAddAlert: StateHandler<boolean>,
  setFailedAddAlert: StateHandler<boolean>,
  setAllTags: StateHandler<Tag[]>,
  setShowView: StateHandler<number>,
  setDisabledFields: StateHandler<boolean>
) => {
  //Create the data object.
  const tagData: PostableTag = {
    tagScanEventId: tagScanEventId!,
    nickname: newTagNickname,
    valid_until: validUntil === "" ? null : validUntil,
    disabled: Number(newDisabled),
  };

  setDisabledFields(true);

  //Call the postTag() service function to send the data to the backend
  const postTagResponse: StructuredResponse<Tag> = await postTag(tagData);

  //Check if the result came back as successful
  if (postTagResponse.success) {
    //Success! Now, trigger the alert to notify the user that the add was successful.
    timer(setSuccessfullAddAlert);

    //Set the newlyAddedTagId to contain the tag id of the newly added tag. This is needed
    //for the success alert as it tells the user the tag id of the new added tag is.
    //Makes it easier for the user to see the tag in their tag list if they wanted a
    //generated tag id for them

    setNewlyAddedTagId(postTagResponse.data.id!);
    await getTags(setAllTags, setShowView); //Fetch all the tags again to update the tags
  } else {
    //Errors occured when trying to add the tag
    logger(postTagResponse.data);
    //Trigger an error alert to notify the user that the add failed
    timer(setFailedAddAlert, 2000);
    return false;
  }
  setDisabledFields(false);
  return true;
};

export const generateTagIdWithCp = async (
  chargePointId: string,
  setBackendScanning: StateHandler<boolean>,
  setBackendScanningComplete: StateHandler<boolean>,
  setFailedScanAlert: StateHandler<boolean>,
  setFailedAddAlert: StateHandler<boolean>,
  setTagExistsAlert: StateHandler<boolean>
) => {
  setBackendScanning(true);
  const response = await getRecentTagScanEvent(chargePointId);
  if (response.data === null) {
    setBackendScanning(false);
    timer(setFailedScanAlert, 6000);
    return new StructuredResponse(false, "No tags scanned on selected chargepoint!");
  }
  if (response.data.id_tag_info == "Blocked") {
    setBackendScanning(false);
    setBackendScanningComplete(true);
    return response;
  }
  if (response.data.id_tag_info == "Accepted") {
    setBackendScanning(false);
    timer(setTagExistsAlert, 6000);
    return response;
  }
  setBackendScanning(false);
  timer(setFailedAddAlert, 6000);
  return response;
};
