import { PostableDefaultTag } from "../../../model/Classes/Tag";
import { postDefaultTag } from "../../../services/userService";

export const addDefaultTag = async () => {
  const newDefaultTag: PostableDefaultTag = {
    nickname: "App",
    valid_until: null,
    disabled: 0,
  };
  const response = await postDefaultTag(newDefaultTag);

  return response;
};
