import { sendForceStop } from "../services/transactionService";
import {
  getAllActiveTransactionsForArea,
  getAllActiveTransactionsForChargepoint,
  searchAllActiveTransactions,
} from "../services/reportingService";
import { timer } from "./timer";
import { logger } from "./logger";
import { LogLevel, Nullable, StateHandler } from "../model/Utilities/Types";
import { ActiveTransaction } from "../model/Classes/Transaction";
import StructuredResponse from "../model/Classes/StructuredResponse";

/**
 * Utility function for force stopping an active transaction. The function initially creates a data object
 * containing the transaction id and area id, and sends it to the backend with the sendForceStop() service
 * function. If it fails, log the error and set the forceStoperror state to true. If its successful, call
 * the correct service function for updating the active transactions (the code knows which to call based on
 * the forWhat parameter). If it fails, log the error and set the forceStopSuccessButRefreshActive state to
 * true. Otherwise, update the allActiveTransactions state with the new data and set the forceStopSuccess
 * state to true.
 *
 * In short, there's three cases:
 * 1) Fails when force stopping: log the error and set forceStopError state to true
 * 2) Remote stopping succesfull, but refreshing active transactions fail: log the error and set the forceStopSuccessButRefreshActive state to true
 * 3) Both are successful: update the activeTransactions state and set the forceStopSuccess state to true
 * @param {*} transactionId id of the transaction to stop
 * @param {*} cpId id of the chargepoint the transactions takes place for
 * @param {*} areaId id of the area where the chargepoint recides
 * @param {*} reportingAreaFieldId id of the area the user has chosen to get the reports for in Reporting.js
 * @param {*} forWhat string indicating which component called the function
 * @param {*} setForceStopSuccess state handler for updating the forceStopSuccess state (shows an alert)
 * @param {*} setForceStopError state handler for updating the forceStopError state (shows an alert)
 * @param {*} setForceStopSuccessButRefreshActive state handler for updating the forceStopSuccessButRefreshActive state (shows an alert)
 * @param {*} setAllActiveTransactions state handler for updating the allActiveTransactions state
 */
export const forceStop = async (
  transactionId: number,
  reportingAreaFieldId: Nullable<number>,
  forWhat: string,
  setForceStopSuccess: StateHandler<boolean>,
  setForceStopError: StateHandler<boolean>,
  setForceStopSuccessButRefreshActive: StateHandler<boolean>,
  setAllActiveTransactions: StateHandler<ActiveTransaction[]>,
  refresh: () => Promise<void>,
  areaId?: number,
  cpId?: string
) => {
  //Create the data object to be sent to the backend
  const data = {
    transaction_id: transactionId,
  };

  //Call the service function sendForceStop to stop the transaction
  const res = await sendForceStop(data);
  if (res.success) {
    logger(res.result, LogLevel.INFO);
    //Check if the force stop was successful
    //It was, now try to refresh the active transactions
    try {
      let activeRes: StructuredResponse<ActiveTransaction[]>; //Create an empty array for the new active transactions
      if (forWhat === "reporting") {
        activeRes = await searchAllActiveTransactions({
          area_id: reportingAreaFieldId,
        });
      } else if (forWhat === "chargepoint" && areaId && cpId) {
        activeRes = await getAllActiveTransactionsForChargepoint({
          areaId: areaId,
          cpId: cpId,
        });
      }
      //The Chargepoint component called the function
      else if (areaId) {
        activeRes = await getAllActiveTransactionsForArea(areaId); //The Single Area component called the function
      } else {
        throw Error("Unexpected error");
      }

      //Everything's successful (Case 3), now update the allActiveTransactions state with the new data and set the
      //forceStopSuccess alert to true
      if (activeRes && activeRes.success) {
        setAllActiveTransactions(activeRes.data);

        timer(setForceStopSuccess);
        refresh();
      }
    } catch (e) {
      //Some error occured when refreshing the active transactions (Case 2)
      logger(e, LogLevel.WARNING);
      //Set the forceStopSuccessButRefreshActive state to true
      timer(setForceStopSuccessButRefreshActive);
    }
  } else {
    //Force stopping failed (Case 1)
    logger(res.error);

    //Set the forceStopError state to true
    timer(setForceStopError);
  }
};
