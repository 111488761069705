import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { Row, InputGroup, DropdownButton, Dropdown, FormControl, Alert } from "react-bootstrap";
import User from "../../../model/Classes/User";
import { Nullable, StateHandler } from "../../../model/Utilities/Types";
import { AreaBase, AreaReporting } from "../../../model/Classes/Area";
import { useEffect, useState } from "react";
import { getSpotPricePendingCount } from "../../../services/myTransactionsService";
import { logger } from "../../../utils/logger";

declare interface InvoiceMethod {
  name: string;
  key: Nullable<number>;
}

declare interface InvoiceFilter {
  name: string;
  key: number;
}

declare interface TransactionsFiltersProps {
  user: User;
  invoiceMethodButtons: InvoiceMethod[];
  setSelectedInvoiceMethod: StateHandler<Nullable<number>>;
  selectedInvoiceMethod: Nullable<number>;
  areas: AreaReporting[];
  areaChanged: (area: AreaBase) => Promise<void>;
  areaField: string;
  invalidDates: boolean;
  startDate: string;
  setStartDate: StateHandler<string>;
  stopDate: string;
  setStopDate: StateHandler<string>;
  invoiceFilter: InvoiceFilter[];
  invoicingFilter: number;
  setInvoicingFilter: StateHandler<number>;
}

export const TransactionsFilters = ({
  user,
  invoiceMethodButtons,
  setSelectedInvoiceMethod,
  selectedInvoiceMethod,
  areas,
  areaChanged,
  areaField,
  invalidDates,
  startDate,
  setStartDate,
  stopDate,
  setStopDate,
  invoiceFilter,
  invoicingFilter,
  setInvoicingFilter,
}: TransactionsFiltersProps) => {
  const { t } = useTranslation("common", { i18n: i18n });

  const [pendingAlert, setPendingAlert] = useState(false);
  useEffect(() => {
    const getSpotPendingCount = async () => {
      const pendingSpotCount = await getSpotPricePendingCount();
      if (pendingSpotCount.success) {
        if (pendingSpotCount.data[0].pendingCount > 0) {
          setPendingAlert(true);
        } else {
          setPendingAlert(false);
        }
      } else {
        if (!pendingSpotCount.success) {
          logger(pendingSpotCount.data);
        }
      }
    };
    getSpotPendingCount();
  }, []);
  // const [selectedTimezone, setSelectedTimezone] = useState(''); //initial state for timezone

  //const [timezones, setTimezones] = useState<string[]>([]);
  //const [searchText, setSearchText] = useState('');
  //const searchInputRef = useRef<HTMLInputElement | null>(null);
  // const [displayedStartDate, setDisplayedStartDate] = useState('');
  //const [displayedStopDate, setDisplayedStopDate] = useState('');
  //GET USER'S TIMEZONE ***working to get system timezone

  /** useEffect(() => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSelectedTimezone(userTimezone);
  }, []);

  //gets all timezones. had to cast intl as any. without that it will give an error.
  const timezoneIntl = (Intl as any).supportedValuesOf('timeZone');
 

  //START OF THE NEW FUNCTION FOR TIMEZONE CONVERSION

  const datetimeInputRef = useRef<HTMLInputElement>(null);
  const [filteredTimezones, setFilteredTimezones] = useState([]);

  const convertToTimeZone = (
    inputDate: Date,
    targetTimeZone: string
  ): string => {
    const formattedDate = new Intl.DateTimeFormat('en', {
      timeZone: targetTimeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    }).format(inputDate);

    return formattedDate;
  };
   */

  /** 
  const convertToTimeZone = (
    inputDate: Date,
    targetTimeZone: string
  ): string => {
    const options : Intl.DateTimeFormatOptions = {
      timeZone: targetTimeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };

    const dateFormatter = new Intl.DateTimeFormat('en', options);

    const [
      { value: year },
      { value: month },
      { value: day },
      { value: hour },
      { value: minute },
      { value: second },
    ] = dateFormatter.formatToParts(inputDate);

    const formattedDate = `${year}-${month}-${day}T${hour}:${minute}:${second}.000Z`;
    return formattedDate;
  };
*/
  //FUNCTION TO CHANGE BACK THE UTC TO LOCAL SO THAT IT CAN BE DISPLAYED IN THE UI
  /**
  useEffect(() => {
    // Function to convert UTC date to "YYYY-MM-DD" format
    const convertUTCtoLocal = (utcDate) => {
      

      const date = new Date(utcDate);
      
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    // Set the formatted start date in "YYYY-MM-DD" format to displayedStartDate
    setDisplayedStartDate(convertUTCtoLocal(startDateUTC));
  
    // Set the formatted end date in "YYYY-MM-DD" format to displayedEndDate
    setDisplayedStopDate(convertUTCtoLocal(stopDateUTC));
  }, [startDateUTC, stopDateUTC]);
 */
  //END OF THE NEW FUNCTION FOR TIMEZONE CONVERSION
  /* const handleStartDateChange = (e) => {
    setStartDateUTC(e.target.value);
  };

  const handleStopDateChange = (e) => {
    setStopDateUTC(e.target.value);
  };

  const handleTimezoneChange = (timezone) => {
    setSelectedTimezone(timezone);
  };
*/
  /**
  const handleStartDateChange = (e) => {
    const inputDate = new Date(e.target.value);

    const targetTimeZone = selectedTimezone;
    const convertedStartDate = convertToTimeZone(inputDate, targetTimeZone);
    const [convertedDateOnly, ConvertedTimeOnly] =
      convertedStartDate.split(', ');
    // Parse the date and time parts
    const [month, day, year] = convertedDateOnly.split('/');
    const [hour, minute, second] = ConvertedTimeOnly.split(':');
    const formattedStartDateTZ = new Date(
      `${year}-${month}-${day}T${hour}:${minute}:${second}.000Z`
    ).toISOString();
    setStartDateUTC(formattedStartDateTZ);
    setStartDate(convertedDateOnly);
    setStartTime(ConvertedTimeOnly);
  };

  const handleStopDateChange = (e) => {
    const inputDate = new Date(e.target.value);

    const targetTimeZone = selectedTimezone;
    const convertedStopDate = convertToTimeZone(inputDate, targetTimeZone);
    const [convertedDateOnly, ConvertedTimeOnly] =
      convertedStopDate.split(', ');
    // Parse the date and time parts
    const [month, day, year] = convertedDateOnly.split('/');
    const [hour, minute, second] = ConvertedTimeOnly.split(':');
    const formattedStopDateTZ = new Date(
      `${year}-${month}-${day}T${hour}:${minute}:${second}.000Z`
    ).toISOString();
    setStopDateUTC(formattedStopDateTZ);
    setStopDate(convertedDateOnly);
    setStopTime(ConvertedTimeOnly);
  };
  const handleTimezoneChange = (timezone) => {
    setSelectedTimezone(timezone);

    // Convert start and stop dates when timezone changes
    if (datetimeInputRef.current) {
      const inputStartDate = new Date(datetimeInputRef.current.value);
      const targetTimeZone = timezone;

      const convertedStartDate = convertToTimeZone(
        inputStartDate,
        targetTimeZone
      );
      setStartDateUTC(convertedStartDate);
      setStartDate(convertedStartDate);

      // Convert stop date if needed (optional)
      // if (stopDatetimeInputRef.current) {
      //   const inputStopDate = new Date(stopDatetimeInputRef.current.value);
      //   const convertedStopDate = convertToTimeZone(
      //     inputStopDate,
      //     targetTimeZone
      //   );
      //   setStopDateUTC(convertedStopDate);
      // }
    }
  };
   */
  //---------------------------------------------------------
  /**
  useEffect(() => {
    // Filter timezones based on searchText and update filteredTimezones state
    const filtered = timezoneIntl.filter((timezone) =>
      timezone.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredTimezones(filtered);
  }, [searchText]);
 */
  return (
    <>
      {user.user_level === 2 && (
        <>
          {pendingAlert && (
            <Alert variant="danger" className="p-2">
              There are pending spotprice transactions
            </Alert>
          )}
          <Row className="mb-3">
            <InputGroup className="mb-3">
              <DropdownButton
                id="invoice_method_buttons"
                title={t("components.reporting.tabs.completed.areainvoice")}
                drop="down"
              >
                {invoiceMethodButtons.map((im, idx) => {
                  return (
                    <Dropdown.Item key={idx} as="button" onClick={() => setSelectedInvoiceMethod(im.key)}>
                      {im.name}
                    </Dropdown.Item>
                  );
                })}
              </DropdownButton>
              <FormControl
                value={
                  selectedInvoiceMethod === null
                    ? t("components.reporting.tabs.completed.table.buttonsFilters.all")
                    : selectedInvoiceMethod === 0
                    ? t("components.reporting.tabs.completed.table.buttonsFilters.test")
                    : selectedInvoiceMethod === 1
                    ? t("components.reporting.tabs.completed.table.buttonsFilters.normal")
                    : "Wattery/Paytrail"
                }
                readOnly
              />
            </InputGroup>
          </Row>
        </>
      )}
      {/*Row containing the area input field as a dropdown*/}
      <Row>
        <>
          <p>
            <strong>{t("components.reporting.tabs.completed.select")}</strong>
          </p>
          <InputGroup className="mb-3">
            <DropdownButton id="dropdown-item-button" title={t("components.reporting.tabs.completed.area")} drop="down">
              {/*Map all the areas to a Dropdown Item where each item displays the area name*/}
              {areas.map((area, idx) => {
                return (
                  //Whenever an item is clicked, set the areaField state to conatin the chosen area
                  <Dropdown.Item key={idx} as="button" onClick={() => areaChanged(area)}>
                    {area.name}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
            {/*The form field displays the name of the area that is chosen. It's readOnly since
                               we want the user to only choose an area from the dropdown*/}
            <FormControl aria-label="Area input" value={areaField} readOnly />
          </InputGroup>
        </>
      </Row>
      {/**
      <Row>
        <>
         
          <InputGroup className="mb-3">
            <DropdownButton
              id="dropdown-item-button"
              title={t('components.reporting.tabs.completed.timezone')}
              drop="down"
            >
              <Dropdown.Item>
                <FormControl
                  placeholder="Search timezone..."
                  aria-label="Search input"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  ref={searchInputRef}
                  onClick={(e) => e.stopPropagation()}
                />
              </Dropdown.Item>
              {/*Map all the timezones to a Dropdown Item where each item displays the timezone name
              {filteredTimezones.map((timezone, idx) => (
                <Dropdown.Item
                  key={idx}
                  onClick={() => handleTimezoneChange(timezone)}
                  as="button"
                >
                  {timezone}
                </Dropdown.Item>
              ))}
              {filteredTimezones.map((timezone, idx) => (
                <Dropdown.Item
                  key={idx}
                  onClick={() => handleTimezoneChange(timezone)}
                  as="button"
                >
                  {timezone}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            {/*The form field displays the name of the timezone that is chosen. It's readOnly since
                               we want the user to only choose a tmezone from the dropdown
            <FormControl
              aria-label="Area input"
              value={selectedTimezone}
              readOnly
            />
          </InputGroup>
        </>
      </Row>
       */}
      {/*Row containing the date input field for the start date and time*/}
      <Row>
        <p>
          <strong>{t("components.reporting.tabs.completed.start")}</strong>
        </p>
        <InputGroup className="mb-3">
          {/*Form field for the start date. It's an html date type*/}
          <FormControl type="date" value={startDate} onChange={(event) => setStartDate(event.target.value)} />
        </InputGroup>
      </Row>

      {/*Row containing the date input field for the stop date and time*/}
      <Row>
        <p>
          <strong>{t("components.reporting.tabs.completed.stop")}</strong>
        </p>
        <InputGroup className="mb-3">
          {/*Form field for the stop date. It's an html date type*/}
          <FormControl type="date" value={stopDate} onChange={(event) => setStopDate(event.target.value)} />
        </InputGroup>
      </Row>
      {/*Row containing a possible alert notifying the user that the chosen start and stop dates are invalid*/}
      <Row>
        {invalidDates && (
          <p style={{ color: "red", marginBottom: "1rem" }}>
            <strong>{t("global.alert.failure.invalidDates")}</strong>
          </p>
        )}
      </Row>
      {user.user_level === 2 && (
        <Row className="mb-3">
          <InputGroup className="mb-3">
            <DropdownButton id="invoice_filter" title={t("components.reporting.tabs.completed.invFilter")} drop="down">
              {invoiceFilter.map((im, idx) => {
                return (
                  <Dropdown.Item key={idx} as="button" onClick={() => setInvoicingFilter(im.key)}>
                    {im.name}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
            <FormControl
              value={
                invoicingFilter === 0
                  ? t("components.reporting.tabs.completed.table.buttonsFilters.showAll")
                  : invoicingFilter === 1
                  ? t("components.reporting.tabs.completed.table.buttonsFilters.invoiced")
                  : t("components.reporting.tabs.completed.table.buttonsFilters.notInvoiced")
              }
              readOnly
            />
          </InputGroup>
        </Row>
      )}
    </>
  );
};
