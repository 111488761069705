import React, { useState, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./global";
import ProtectedRoute from "./components/GeneralComponents/ProtectedRoute";
import {
  AreaView,
  Burger,
  Menu,
  Login,
  Register,
  LandingPage,
  MyTransactions,
  ResetPassword,
  MyMonthlyTransactions,
  Reporting,
  MyYearlyTransactions,
  SingleAreaView,
  UserSettings,
  ChargepointView,
  ConfirmEmail,
  StartNewTransaction,
  AdminPage,
  Help,
  AddCardFormSuccess,
  AddCardFormFail,
} from "./components/indexComponents";
import { useOnClickOutside } from "./hooks";
import { sendUserSettingsLanguage } from "./services/userService";
import User from "./model/Classes/User";
import { Nullable } from "./model/Utilities/Types";
import { LightTheme } from "./theme";
import { LangDropdown } from "./components/public/LangDropdown/LangDropdown";
import { storageKey as languageStorageKey } from "./i18n";
import { useTranslation } from "react-i18next";

/**
 * Main app component that is responsible for which component and it's subcomponents to view.
 * Also responsible for authenticating the user with the backend on each rerender (see the
 * useEffect() function below).
 *
 * @returns the page to view
 */
function App() {
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false); //state for the menu
  const [user, setUser] = useState<Nullable<User>>(null); //state for the user object
  const [registrationSuccessAlert, setRegistrationSuccessAlert] = useState(false); //state for the alert notifying if registration was successful
  const [logoutSuccessAlert, setLogoutSuccessAlert] = useState(false); //state for the alert notifying if logout was successful
  const [resetPasswordSuccessAlert, setResetPasswordSuccessAlert] = useState(false); //state for the alert notifying if resetting a password was successful
  const [confirmEmailSuccess, setConfirmEmailSuccess] = useState(false); //state for the alert notifying if confirming an email was successful
  const [confirmEmailFail, setConfirmEmailFail] = useState(false); //state for the alert notifying if confirming an email failed
  const menuId = "main-menu";

  const node = React.createRef<HTMLDivElement>();
  useOnClickOutside(node, () => setOpen(false));

  //history object that is used throughout the application
  const history = useHistory();

  // Set language from db (JWT), if we fallback to legacy localStorage then also store it in db
  useEffect(() => {
    const asyncFunc = async () => {
      // Do we have a user, are we logged in? If not, do nothing.
      if (!user) {
        return;
      }

      // User has a language set in the database, which is not the same as i18n uses
      if (user?.language && i18n.language !== user.language) {
        await i18n.changeLanguage(user.language);
        localStorage.setItem(languageStorageKey, user.language);
      }
      // User has no language set in the database, but i18n has detected one
      else if (!user?.language && i18n.language) {
        // Store the language in the database
        await sendUserSettingsLanguage({ language: i18n.language });
        localStorage.setItem(languageStorageKey, i18n.language);
      }
      // Else we do nothing, since we either have the same language in the db
      // as in i18n, or neither of them know anything about the preferred language.
    };
    // Construct for async useEffect
    asyncFunc();
  }, [user, i18n]);

  // If the user has received an email that invoicing failed, there will be
  // a link to the payment methods tab in that mail. Here we use the same history
  // state as on the landing page to show the right tab.
  if (history.location.pathname.startsWith("/settings/payment-methods")) {
    history.push({
      pathname: "/settings",
      state: { fromLandingPageShowCards: true },
    });
  }

  /**
   * The entire return value is wrapped in a a theme provider, allowing the theme to be accessed from any
   * component without passing it as a prop. The components wrapped inside the theme provider are as following:
   * GlobalStyles:
   *  - styled component that contains all the global styles for the entire application
   */
  return (
    <ThemeProvider theme={LightTheme}>
      <>
        <GlobalStyles />
        <div>
          <div>
            <Switch>
              <Route exact path="/register" />
              <Route>
                <LangDropdown i18n={i18n} autoStore={!!user} />
              </Route>
            </Switch>
            <ToastContainer draggable />
          </div>
          <div ref={node} id="hamburger-icon">
            <Burger open={open} setOpen={setOpen} user={user} aria-controls={menuId} />
            <Menu
              open={open}
              setOpen={setOpen}
              user={user}
              setUser={setUser}
              id={menuId}
              setLogoutSuccessAlert={setLogoutSuccessAlert}
              history={history}
            />
          </div>
          <Switch>
            <ProtectedRoute setUser={setUser} exact path="/area/:id/chargepoint/:charge_point_id">
              {user ? (
                user.user_level > 0 ? (
                  <ChargepointView user={user} history={history} />
                ) : (
                  <div className="flex top-level-component">
                    <h2 className="align-self-center text-align-center width-100">{t("global.view.noAccess")}</h2>
                  </div>
                )
              ) : (
                <></>
              )}
            </ProtectedRoute>
            <ProtectedRoute setUser={setUser} exact path="/area/:id">
              {user ? (
                user.user_level > 0 ? (
                  <SingleAreaView user={user} history={history} />
                ) : (
                  <div className="flex top-level-component">
                    <h2 className="align-self-center text-align-center width-100">{t("global.view.noAccess")}</h2>
                  </div>
                )
              ) : (
                <></>
              )}
            </ProtectedRoute>
            <ProtectedRoute setUser={setUser} exact path="/startNewTransaction/:charge_point_id">
              {user ? <StartNewTransaction user={user} history={history} /> : <></>}
            </ProtectedRoute>
            <ProtectedRoute setUser={setUser} exact path="/startNewTransaction">
              <StartNewTransaction user={user} history={history} />
            </ProtectedRoute>
            <ProtectedRoute setUser={setUser} exact path="/reporting">
              {user ? (
                user.user_level > 0 ? (
                  <Reporting user={user} />
                ) : (
                  <div className="flex top-level-component">
                    <h2 className="align-self-center text-align-center width-100">{t("global.view.noAccess")}</h2>
                  </div>
                )
              ) : (
                <></>
              )}
            </ProtectedRoute>
            <ProtectedRoute setUser={setUser} exact path="/myTransactions/:year/:month">
              {user ? <MyMonthlyTransactions user={user} history={history} /> : <></>}
            </ProtectedRoute>
            <ProtectedRoute setUser={setUser} exact path="/myTransactions/:year">
              {user ? <MyYearlyTransactions user={user} history={history} /> : <></>}
            </ProtectedRoute>
            <ProtectedRoute setUser={setUser} exact path="/myTransactions">
              {user ? <MyTransactions user={user} /> : <></>}
            </ProtectedRoute>
            <ProtectedRoute setUser={setUser} exact path="/area">
              {user ? (
                user.user_level > 0 ? (
                  <AreaView user={user} history={history} />
                ) : (
                  <div className="flex top-level-component">
                    <h2 className="align-self-center text-align-center width-100">{t("global.view.noAccess")}</h2>
                  </div>
                )
              ) : (
                <></>
              )}
            </ProtectedRoute>
            <ProtectedRoute setUser={setUser} path="/paytrail/addCardForm/success">
              <AddCardFormSuccess history={history} />
            </ProtectedRoute>
            <ProtectedRoute setUser={setUser} exact path="/paytrail/addCardForm/failed">
              <AddCardFormFail history={history} />
            </ProtectedRoute>
            <ProtectedRoute setUser={setUser} exact path="/settings">
              {user ? <UserSettings user={user} setUser={setUser} history={history} /> : <></>}
            </ProtectedRoute>
            <ProtectedRoute setUser={setUser} exact path="/help">
              {user ? <Help user={user} /> : <></>}
            </ProtectedRoute>
            <ProtectedRoute setUser={setUser} exact path="/admin">
              {user ? (
                user.user_level === 2 ? (
                  <AdminPage />
                ) : (
                  <div className="flex top-level-component">
                    <h2 className="align-self-center text-align-center width-100">{t("global.view.noAccess")}</h2>
                  </div>
                )
              ) : (
                <>
                  <div className="flex top-level-component">
                    <h2 className="align-self-center text-align-center width-100">{t("global.view.noAccess")}</h2>
                  </div>
                </>
              )}
            </ProtectedRoute>
            <ProtectedRoute setUser={setUser} exact path="/login">
              <Login
                setUser={setUser}
                user={user}
                registrationSuccessAlert={registrationSuccessAlert}
                logoutSuccessAlert={logoutSuccessAlert}
                resetPasswordSuccessAlert={resetPasswordSuccessAlert}
                history={history}
                confirmEmailSuccess={confirmEmailSuccess}
                confirmEmailFail={confirmEmailFail}
              />
            </ProtectedRoute>
            <ProtectedRoute setUser={setUser} exact path="/">
              {user && <LandingPage user={user} history={history} />}
            </ProtectedRoute>

            {/* Unprotected routes */}
            <Route exact path="/register">
              <Register setRegistrationSuccessAlert={setRegistrationSuccessAlert} history={history} user={user} />
            </Route>
            <Route exact path="/resetPassword/:token">
              <ResetPassword history={history} setResetPasswordSuccessAlert={setResetPasswordSuccessAlert} />
            </Route>
            <Route exact path="/confirmEmail/:token">
              <ConfirmEmail
                history={history}
                setConfirmEmailSuccess={setConfirmEmailSuccess}
                setConfirmEmailFail={setConfirmEmailFail}
              />
            </Route>

            <Route>
              <div className="flex top-level-component">
                <h2 className="align-self-center text-align-center width-100">Error 404: Page not found</h2>
              </div>
            </Route>
          </Switch>
        </div>
      </>
    </ThemeProvider>
  );
}

export default App;
