import { tokenRenewingGet, tokenRenewingPost } from "./lib/tokenRenewingRequest";
import { ActiveTransaction, ReportingTransaction } from "../model/Classes/Transaction";
import { AreaReporting } from "../model/Classes/Area";
import ITuple from "../model/Utilities/Tuple";

export const getAllTransactionsDefaultCompleted = async (data: any) =>
  await tokenRenewingPost<ITuple<ReportingTransaction[], AreaReporting[]>>("/api/reporting/default", data);

export const getAllTransactionsDefaultActive = async () =>
  await tokenRenewingGet<ActiveTransaction[]>("/api/reporting/default/active");

export const searchAllTransactions = async (data: any) =>
  await tokenRenewingPost<ReportingTransaction[]>("/api/reporting/specific", data);

export const searchAllActiveTransactions = async (data: any) =>
  await tokenRenewingPost<ActiveTransaction[]>("/api/reporting/specific/active", data);

export const getAllActiveTransactionsForArea = async (id: number) =>
  await tokenRenewingGet<ActiveTransaction[]>(`/api/reporting/active/${id}`);

export const getAllActiveTransactionsForChargepoint = async (data: { areaId: number; cpId: string }) =>
  await tokenRenewingGet<ActiveTransaction[]>(`/api/reporting/active/${data.areaId}/${data.cpId}`);

export const groupUpdatePaymentMethodStatus = async (user_emails: any) =>
  await tokenRenewingPost<any>("/api/reporting/card_expiry", user_emails);
