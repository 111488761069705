import { tokenRenewingGet, tokenRenewingPost } from "./lib/tokenRenewingRequest";
import AreaSingle, { AreaBase } from "../model/Classes/Area";
import User from "../model/Classes/User";
import AreaMeter from "../model/Classes/AreaMeter";
import AreaMeterValue from "../model/Classes/AreaMeterValue";
import BiddingArea from "../model/Classes/BiddingArea";
import CpFull from "../model/Classes/Chargepoint";

// Area CRUD Operations
export const addArea = async (parentId: number, data: any) =>
  await tokenRenewingPost<any>(`/api/area/${parentId}/add`, data);

export const addDefaultArea = async (parentId: number, data: any) =>
  await tokenRenewingPost<any>(`/api/admin/area/${parentId}/addDefault`, data);

export const sendAreaChanges = async (id: number, data: any) =>
  await tokenRenewingPost<AreaSingle>(`/api/area/${id}/edit`, data);

export const sendAreaDelete = async (id: number, data: string[]) =>
  await tokenRenewingPost<number>(`/api/area/${id}/del`, data);

export const sendAreaMove = async (id: number, data: string) =>
  await tokenRenewingPost<number>(`/api/area/${id}/move`, data);

// Area Access Management
export const authorizeUser = async (areaId: number, data: any) =>
  await tokenRenewingPost<any>(`/api/area/${areaId}/authorize`, data);

export const deauthorizeUsers = async (areaId: number) =>
  await tokenRenewingPost<any>(`/api/area/${areaId}/revokeAllChargingAccess`, {});

export const revokeAuthorization = async (areaId: number, data: any) =>
  await tokenRenewingPost<any>(`/api/area/${areaId}/revokeChargingAccess`, data);

// Area Settings
export const toggleAlerts = async (areaId: number) =>
  await tokenRenewingPost<boolean>(`/api/area/${areaId}/toggleAlerts`, null);

export const toggleFreeCharging = async (areaId: number, data: { user_id: number }) =>
  await tokenRenewingPost<boolean>(`/api/area/${areaId}/toggleFreeCharging`, data);

// Area Queries
export const getAreaBranches = async <T extends AreaBase>(apiAddress: string, area_id?: number) =>
  await tokenRenewingGet<T[][]>(`${apiAddress}${area_id ? `/${area_id}` : ""}`);

export const getAuthorizedUsers = async (areaId: number) =>
  await tokenRenewingGet<User[]>(`/api/area/${areaId}/chargingAccess`);

export const getBiddingAreaValue = async () => await tokenRenewingGet<BiddingArea[]>("/api/area/getBiddingAreaValue");

export const getChargepoints = async (id: number) => await tokenRenewingGet<CpFull[]>(`/api/area/${id}/chargepoints`);

export const getChargepointsOverload = async (id: number) =>
  await tokenRenewingGet<CpFull[]>(`/api/area/${id}/chargepoints/all`);

export const getIsLoadBalanced = async (charge_point_id: string) =>
  await tokenRenewingGet<boolean>(`/api/transactions/isLoadBalanced/${charge_point_id}`);

export const getSingleChargepoint = async (area_id: number, charge_point_id: string) =>
  await tokenRenewingGet<CpFull>(`/api/area/${area_id}/chargepoint/${charge_point_id}`);

export const getUserRootArea = async () => await tokenRenewingGet<number>("/api/area/user");

export const getUserRootAreas = async () =>
  await tokenRenewingGet<{ area_id: number; primary: number; alerts: number }[]>("/api/area/user");

// Area Meters
export const getAreaMeters = async () => await tokenRenewingGet<AreaMeter[]>("/api/area/area_meters");

export const getAreaMeterValues = async (data: any) =>
  await tokenRenewingPost<AreaMeterValue[]>("/api/area/meterValues", data);

export const sendMeterChanges = async (
  area_id: number,
  newMeter: AreaMeter,
  oldMeter: AreaMeter,
  had: boolean,
  has: boolean
) => await tokenRenewingPost<any>(`/api/area/${area_id}/area_meters`, [newMeter, oldMeter, had, has]);
