import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { sendTokenizationId } from "../../../services/paytrailService";
import { logger } from "../../../utils/logger";

const AddCardFormSuccess = ({ history }) => {
  const params = new URLSearchParams(useLocation().search);

  useEffect(() => {
    const sendTokenization = async () => {
      const data = {
        checkout_account: String(params.get("checkout-account")),
        checkout_algorithm: String(params.get("checkout-algorithm")),
        checkout_method: String(params.get("checkout-method")),
        checkout_status: String(params.get("checkout-status")),
        checkout_tokenization_id: String(params.get("checkout-tokenization-id")),
        signature: String(params.get("signature")),
      };

      try {
        await sendTokenizationId(data);
        history.push({
          pathname: "/settings",
          state: { addCardSuccess: true },
        });
      } catch (e: any) {
        logger(e);
        if (e.response?.data?.error?.message === "card already added") {
          history.push({
            pathname: "/settings",
            state: { addCardExists: true },
          });
        }
        history.push({
          pathname: "/settings",
          state: { addCardFailed: true },
        });
      }
    };

    sendTokenization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex top-level-component">
      <h2 className="align-self-center text-align-center width-100">Loading, please wait...</h2>
    </div>
  );
};

export default AddCardFormSuccess;
