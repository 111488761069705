import { KeyboardEvent, SyntheticEvent, useState } from "react";
import { InputGroup, FormControl, Button, Modal, Col, Alert } from "react-bootstrap";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { Contract } from "../../../model/Classes/Contract";
import { StateHandler } from "../../../model/Utilities/Types";
import { emailRegex } from "../../../utils/emailRegex";
import { updatePrimaryManager } from "../../../services/adminService";
import { toggleAlertsOff } from "../../../utils/toggleAlertsOff";
import { timer } from "../../../utils/timer";

interface ContractTableProps {
  contracts: Contract[];
  setSelectedContract: StateHandler<Contract>;
  toggleView: () => void;
  setRunUseEffect: StateHandler<boolean>;
}

export const ContractTable = ({ contracts, setSelectedContract, toggleView, setRunUseEffect }: ContractTableProps) => {
  const { t } = useTranslation("common", { i18n: i18n });

  const [primary, setPrimary] = useState("");
  const [previous, setPrevious] = useState("");
  const [cNumber, setCNumber] = useState(-1);
  const [show, setShow] = useState(false);

  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [alertMessage, setAlertMessage] = useState("Invalid email");

  const rowEvents = {
    onClick: (e: SyntheticEvent, row: Contract, rowIndex: number) => {
      if ((e.target as HTMLElement).id === "update-manager") {
        return;
      }
      setSelectedContract(row);
      toggleView();
    },
  };

  const rowStyles = {
    cursor: "pointer",
  };

  const columns: ColumnDescription<Contract>[] = [
    {
      dataField: "contract_number",
      text: t("components.admin.contracts.number"),
    },
    {
      dataField: "name",
      text: t("components.admin.contracts.name"),
    },
    {
      dataField: "business_id",
      text: t("components.admin.contracts.bId"),
    },
    {
      dataField: "update_manager",
      isDummyField: true,
      text: "Manager",
      formatter: (cell, row, rowIndex, extraData) => (
        <>
          {/**for the moment, disable button */}
          <Button
            id="update-manager"
            onClick={() => {
              setCNumber(row.contract_number!);
              setShowModal(true, row);
            }}
            variant="primary"
          >
            {`Update manager`}
          </Button>
        </>
      ),
    },
  ];

  async function submitPrimary() {
    toggleAlertsOff([setFail, setSuccess]);
    if (!emailRegex.test(primary)) {
      timer(setFail);
      return;
    } else {
      const ret = await updatePrimaryManager(cNumber, primary);
      setAlertMessage(ret.data.message);
      if (ret.success) {
        timer(setSuccess);
        setRunUseEffect(true);
      } else {
        timer(setFail);
      }
      setShow(false);
      setPrimary("");
    }
  }

  async function setShowModal(show: boolean, row?: Contract) {
    if (!row || !show) return setShow(false);
    setShow(show);
    setPrevious(row.primary_manager ? row.primary_manager : "");
  }

  return (
    <>
      {success ? (
        <Col className="mb-3">
          <Alert variant="success">{alertMessage}</Alert>
        </Col>
      ) : null}
      {fail ? (
        <Col className="mb-3">
          <Alert variant="danger">{alertMessage}</Alert>
        </Col>
      ) : null}
      <ToolkitProvider
        bootstrap4
        keyField="contract_number"
        data={contracts}
        columns={columns}
        data-cy="contract-table"
        search={true}
      >
        {(props) => (
          <>
            <ContractSearch {...props.searchProps} />
            <BootstrapTable
              id="c-table"
              striped
              bordered
              hover
              noDataIndication="Table is Empty"
              defaultSorted={[{ dataField: "contract_number", order: "asc" }]}
              pagination={paginationFactory({
                hidePageListOnlyOnePage: true,
              })}
              rowEvents={rowEvents}
              rowStyle={rowStyles}
              {...props.baseProps}
            />
          </>
        )}
      </ToolkitProvider>
      <Modal show={show} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>Update primary manager</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Text>Current manager</InputGroup.Text>
            <FormControl disabled={true} value={previous} />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text>New manager</InputGroup.Text>
            <FormControl
              value={primary}
              placeholder="@"
              type="text"
              onChange={(event) => setPrimary(event.target.value)}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => submitPrimary()}>
            Update
          </Button>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const ContractSearch = (props) => {
  let search: HTMLInputElement;
  const handleClick = () => {
    props.onSearch(search.value);
  };

  const handleEmpty = () => {
    if (search.value.length === 0) handleClick();
  };
  return (
    <InputGroup className="mb-3">
      <FormControl
        data-cy="search-contract"
        id="search-contract"
        placeholder="Business ID, contract name"
        type="text"
        ref={(n: HTMLInputElement) => (search = n)}
        onChange={handleEmpty}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") handleClick();
        }}
      />
      <Button variant="primary" onClick={handleClick} onMouseDown={(e) => e.preventDefault()}>
        Search
      </Button>
    </InputGroup>
  );
};
