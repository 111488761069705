import { tokenRenewingGet, tokenRenewingPost, tokenRenewingPut } from "./lib/tokenRenewingRequest";
import { TransactionCp } from "../model/Classes/Chargepoint";
import DiagnosticsMessage from "../model/Classes/DiagnosticsMessage";

export const acknowledgeChargepointMessages = async (charge_point_id: string, data: any) =>
  await tokenRenewingPut<any>(`/api/diagnostics/acknowledge/${charge_point_id}`, data);

export const addChargepoint = async (parent_id: number, data: any) =>
  await tokenRenewingPost<any>(`/api/area/${parent_id}/chargepoint/add`, data);

export const getAccessToCp = async (charge_point_id: string) =>
  await tokenRenewingGet<any>(`/api/user/chargingAccess/specific/${charge_point_id}`);

export const getChargepointIsConnected = async (charge_point_id: string) =>
  await tokenRenewingGet<any>(`/api/user/chargingAccess/connected/${charge_point_id}`);

export const getChargingAccess = async () => await tokenRenewingGet<TransactionCp[]>("/api/user/chargingAccess");

export const getChargingAccessWatteryOnly = async () =>
  await tokenRenewingGet<any[]>("/api/user/chargingAccess/watteryOnly");

export const getChargingAccessReporting = async () =>
  await tokenRenewingGet<any[]>("/api/user/chargingAccess/reporting");

export const getCpDiagnosticMessages = async (charge_point_id: string) =>
  await tokenRenewingGet<DiagnosticsMessage[]>(`/api/diagnostics/${charge_point_id}`);

export const getCpSpotprice = async (charge_point_id: string) =>
  await tokenRenewingGet<any>(`/api/user/getSpotprice/${charge_point_id}`);

export const getCurrentSpotprice = async (charge_point_id: string) =>
  await tokenRenewingGet<any>(`/api/user/getCurrentSpotprice/${charge_point_id}`);

export const getPublicChargepoints = async () =>
  await tokenRenewingGet<TransactionCp[]>("/api/user/chargingAccess/public");

export const sendChargepointMove = async (cpId: string, data: any) =>
  await tokenRenewingPost<any>(`/api/area/${data.old_area_id}/chargepoint/${cpId}/move`, data);

export const sendChargepointChanges = async (areaId: number, cpId: string, data: any) =>
  await tokenRenewingPost<any>(`/api/area/${areaId}/chargepoint/${cpId}/edit`, data);

export const sendChargepointDelete = async (areaId: number, cpId: string) =>
  await tokenRenewingPost<any>(`/api/area/${areaId}/chargepoint/${cpId}/del`, {});
