import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { Contract } from "../../../model/Classes/Contract";
import {
  Alert,
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  FormControl,
  InputGroup,
  ListGroup,
  Row,
} from "react-bootstrap";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  ContractStatus,
  InvoiceChannel,
  InvoicingMethod,
  Key,
  LogLevel,
  StateHandler,
} from "../../../model/Utilities/Types";
import { AddEditModal } from "../Area/Settings/AddEditModal";
import { toggleAlertsOff } from "../../../utils/toggleAlertsOff";
import { addContract, getContract, updateContract } from "../../../services/adminService";
import { AreaNameAndParent } from "../Area/Settings/AreaSettingsGeneral";
import { AreaBase } from "../../../model/Classes/Area";
import { getAreaBranches } from "../../../services/areaService";
import { logger } from "../../../utils/logger";
import { numStrOrDef, strOrDef, valOrDef } from "../../../model/Utilities/Extensions";
import { StyledContract } from "./Contract.styled";
import { toModalString, formatDisplayIBAN, toModalStringArray } from "./ContractUtils";
import { timer } from "../../../utils/timer";
import { Link } from "react-router-dom";
import { addDefaultArea } from "../../../services/areaService";
import { emailRegex } from "../../../utils/emailRegex";
import DynamicListForm from "../../GeneralComponents/DynamicListForm";

interface EditContractProps {
  toggleView: () => void;
  contract: Contract;
  addMode: boolean;
  setRunUseEffect: StateHandler<boolean>;
  setSelectedContract: StateHandler<Contract>;
  admin: boolean;
  setAddSuccessAlert: StateHandler<boolean>;
}

const isDev = process.env.NODE_ENV === "development";

export const rationalNumberRegex = /\d+[,.]\d+|\d+/;

export const isNotDigitOrComma = /[a-zA-Z\s]+/;

export const EditContract = ({
  toggleView,
  contract,
  addMode,
  setRunUseEffect,
  setSelectedContract,
  admin,
  setAddSuccessAlert,
}: EditContractProps) => {
  //#region form fields
  const { t } = useTranslation("common", { i18n: i18n });
  const [name, setName] = useState(``);
  const [bId, setBId] = useState("");
  const [address, setAddress] = useState("");
  const [addressSpecifier, setAddressSpecifier] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  //Set default invoice_method to "None"
  const [invMethod, setInvMethod] = useState<Key<typeof InvoicingMethod>>(0);
  const [vat, setVat] = useState("");
  const [bank, setBank] = useState("");
  const [orderRef, setOrderRef] = useState("");
  const [paymentRef, setPaymentRef] = useState("");
  //Set default invoice_channel to "Ei lähetetä"
  const [invChannel, setInvChannel] = useState<Key<typeof InvoiceChannel>>(6);
  const [billingAddress, setBillingAddress] = useState("");
  //Set default status to "Created"
  const [status, setStatus] = useState<Key<typeof ContractStatus>>(0);
  const [terms, setTerms] = useState("");
  const [pManager, setPManager] = useState("");
  const [notes, setNotes] = useState("");
  const [reportReceivers, setReportReceivers] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [showView, setShowView] = useState(-1);
  const [displayDate, setDisplayDate] = useState("");
  const [eEdi, setEEdi] = useState("");
  const [eAddress, setEAddress] = useState("");
  const [eOperator, setEOperator] = useState("");
  const [invEmail, setInvEmail] = useState("");
  const [defaultCpPrice, setDefaultCpPrice] = useState("0.15");
  //#endregion
  const [hasRootArea, setHasRootArea] = useState(false);

  //#region form alerts
  const [nameAlert, setNameAlert] = useState(false);
  const [bIdAlert, setbIdAlert] = useState(false);
  const [vatAlert, setVatAlert] = useState(false);
  const [bankAlert, setBankAlert] = useState(false);
  const [pManagerAlert, setPManagerAlert] = useState(false);
  const [billAlert, setBillAlert] = useState(false);
  const [paymentRefAlert, setPaymentRefAlert] = useState(false);
  const [orderRefAlert, setOrderRefAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [areaNameAlert, setAreaNameAlert] = useState(false);
  const [eInvoiceAlert, setEInvoiceAlert] = useState(false);
  const [invoiceAlert, setInvoiceAlert] = useState(false);
  const [locationAlert, setLocationAlert] = useState(false);

  const [addAreaSuccess, setAddAreaSuccess] = useState(false);
  const [addAreaFail, setAddAreaFail] = useState(false);

  const isFormAlert = useCallback(() => {
    return (
      nameAlert ||
      bIdAlert ||
      vatAlert ||
      bankAlert ||
      billAlert ||
      paymentRefAlert ||
      orderRefAlert ||
      eInvoiceAlert ||
      invoiceAlert ||
      locationAlert
    );
  }, [
    nameAlert,
    bIdAlert,
    vatAlert,
    bankAlert,
    billAlert,
    paymentRefAlert,
    orderRefAlert,
    eInvoiceAlert,
    invoiceAlert,
    locationAlert,
  ]);
  //#endregion

  //#region area part
  const [areaName, setAreaName] = useState("");
  const [parent, setParent] = useState<AreaBase>(() => {
    return { id: 0, name: "Root area" };
  });

  const [disabledFields, setDisabledFields] = useState(false);
  const [areas, setAreas] = useState<AreaBase[]>([]);
  const [internalRunUF, setInternalRunUF] = useState(true);
  //#endregion

  const [apiAddress] = useState(admin ? "/api/admin/contract" : "/api/reporting/contract");
  const [changes, setChanges] = useState<string[]>([]);

  const areaApiAddress = admin ? "/api/admin/area" : "/api/area";

  const alertRef = useRef<HTMLDivElement>(null);

  const allowDotAndComma = (field: string) => {
    const ret = rationalNumberRegex.test(field);
    let real: string | undefined | null = null;
    if (ret) {
      const match = field.toString().match(rationalNumberRegex);
      real = match?.[0].replace(",", ".");
      setDefaultCpPrice(real ? real : "");
    }
    return ret && Number(real) >= 0;
  };

  useEffect(() => {
    const getData = async () => {
      const contractRes = await getContract(contract.contract_number!, apiAddress);

      if (contractRes.success) {
        const c = contractRes.data;
        setSelectedContract(c[0]);
        setFields(c[0]);
        setHasRootArea(c[1].length > 0);
        if (c[1].find((a) => a.id === 0)) {
          setAreas(c[1]);
        } else {
          setAreas([parent, ...c[1]]);
        }
        //If everything works out, show the EditContract form
        setShowView(1);
      } else {
        logger(contractRes.data);
        setShowView(0);
      }
      setInternalRunUF(false);
    };

    if (!addMode && internalRunUF) getData();
    //eslint-disable-next-line
  }, [internalRunUF]);

  useEffect(() => {
    const getData = async () => {
      const areasRes = await getAreaBranches<AreaBase>(areaApiAddress);
      if (areasRes.success) {
        setAreas(
          areasRes.data
            .flatMap((a) => a)
            .sort((a, b) => {
              return b.name!.toLowerCase() < a.name!.toLowerCase() ? 1 : -1;
            })
        );
        setShowView(1);
      } else {
        setShowView(0);
        logger(areasRes.data);
      }
    };

    if (addMode) getData();
  }, [addMode, areaApiAddress]);

  //For scrolling to alert section
  useEffect(() => {
    if (isFormAlert()) {
      setTimeout(() => {
        alertRef.current!.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }, 100);
    }
  }, [isFormAlert]);

  const setFields = (c: Contract) => {
    setName(strOrDef(c.name));
    setAddress(strOrDef(c.street_address));
    setBId(strOrDef(c.business_id));
    setInvMethod(valOrDef(c.invoicing_method, 0));
    setInvChannel(valOrDef(c.invoice_channel, 6));
    setVat(numStrOrDef(c.vat));
    setZip(strOrDef(c.zip));
    setReportReceivers(c.report_receivers ? c.report_receivers.toString().trim().split(",") : [""]);
    setCity(strOrDef(c.city));
    setInvEmail(strOrDef(c.invoice_email));
    setCountry(strOrDef(c.country));
    setBank(strOrDef(c.bank_account));
    setBillingAddress(strOrDef(c.billing_address));
    setPaymentRef(strOrDef(c.payment_reference));
    setNotes(strOrDef(c.notes));
    setStatus(valOrDef(c.contract_status, 0));
    setEOperator(strOrDef(c.einvoice_operator));
    setEAddress(strOrDef(c.einvoice_address));
    setEEdi(strOrDef(c.einvoice_edi));
    setAddressSpecifier(strOrDef(c.address_specifier));
    //Ignore time component to display date correctly in date-time-picker
    setDisplayDate(strOrDef(c.terms_accepted).split("T")[0]);
    setTerms(strOrDef(c.terms_accepted));
    setOrderRef(strOrDef(c.invoice_reference));
    setPManager(strOrDef(c.primary_manager));
    setDefaultCpPrice(valOrDef(c.default_chargepoint_price, 0.15).toString());
  };

  const submit = async () => {
    setDisabledFields(true);
    toggleAlertsOff([
      setNameAlert,
      setbIdAlert,
      setVatAlert,
      setBankAlert,
      setBillAlert,
      setPaymentRefAlert,
      setOrderRefAlert,
      setEInvoiceAlert,
      setInvoiceAlert,
      setLocationAlert,
      setErrorAlert,
      setAddAreaFail,
      setAddAreaSuccess,
      setAddSuccessAlert,
    ]);

    const contr: Contract = {
      name: name,
      contract_number: contract.contract_number,
      business_id: bId,
      billing_address: billingAddress,
      invoicing_method: invMethod,
      street_address: address,
      address_specifier: addressSpecifier.length > 0 ? addressSpecifier : null,
      zip: zip,
      city: city,
      country: country,
      report_receivers: reportReceivers.length !== 0 ? reportReceivers.toString() : "",
      contract_status: status,
      einvoice_address: eAddress.length > 0 ? eAddress : null,
      einvoice_edi: eEdi.length > 0 ? eEdi : null,
      einvoice_operator: eOperator.length > 0 ? eOperator : null,
      invoice_channel: invChannel,
      terms_accepted: terms.length > 0 ? new Date(terms).toISOString() : null,
      vat: invMethod !== 2 ? 0 : Number(vat),
      payment_reference: invMethod !== 2 ? null : paymentRef,
      bank_account: invMethod !== 2 ? null : bank,
      notes: notes,
      invoice_email: invChannel === 1 ? invEmail : "",
      primary_manager: pManager.length > 0 ? pManager : null,
      invoice_reference: orderRef,
      default_chargepoint_price: Number(defaultCpPrice),
    };

    if (addMode) {
      const res = await addContract(contr);

      if (res.success) {
        timer(setAddSuccessAlert);
        toggleView();
        setRunUseEffect(true);
        return;
      } else {
        timer(setErrorAlert);
        logger(res.data);
      }
      setDisabledFields(false);
      setShowModal(false);
      return;
    }

    const updateRes = await updateContract(apiAddress, contr);
    if (updateRes.success) {
      timer(setSuccessAlert);
      setRunUseEffect(true);
      setChanges([]);
    } else {
      timer(setErrorAlert);
      logger(updateRes.data);
    }
    setShowModal(false);
    setDisabledFields(false);
  };

  useEffect(() => {
    if (invChannel !== 3) {
      emptyFieldsOnHide(setEOperator, setEAddress, setEEdi);
    }
    if (invMethod !== 2) {
      emptyFieldsOnHide(setBank, setVat, setPaymentRef);
    }
  }, [invChannel, invMethod]);

  const emptyFieldsOnHide = (...fields: StateHandler<string>[]) => {
    for (const f of fields) {
      f("");
    }
  };

  const validate = () => {
    let success = true;

    if (name.length === 0) {
      success = false;
      setNameAlert(true);
    }
    if (!address.length) {
      success = false;
    }

    if (!zip.length || !city.length || !country.length || !address.length) {
      success = false;
      setLocationAlert(true);
    }

    if (invChannel === 3) {
      if (!eOperator.length || !eAddress.length) {
        success = false;
        setEInvoiceAlert(true);
      }
    }
    //|| !paymentRef.length (removed from below to disabe validation of paymentref)
    if (invMethod === 2) {
      if (!bank.length || !vat.length) {
        success = false;
        setInvoiceAlert(true);
      }
    }

    return success;
  };

  const handleDate = (value: string) => {
    setDisplayDate(value);
    setTerms(value);
  };

  const showChanges = () => {
    toggleAlertsOff([
      setNameAlert,
      setbIdAlert,
      setVatAlert,
      setBankAlert,
      setPManagerAlert,
      setBillAlert,
      setPaymentRefAlert,
      setOrderRefAlert,
      setErrorAlert,
    ]);

    if (!validate()) return;
    const modalArr: string[] = [];

    reportReceivers.filter((a) => {
      return a.length > 0 && emailRegex.test(a);
    });

    //For mapped fields, set string equivalents first, then compare
    const oldInvMethod =
      contract.invoicing_method !== undefined && contract.invoicing_method !== null
        ? t(`components.admin.contracts.invMethod.${InvoicingMethod[contract.invoicing_method]}`)
        : t(`components.admin.contracts.invMethod.${InvoicingMethod[invMethod]}`);
    const oldInvChannel =
      contract.invoice_channel !== undefined && contract.invoice_channel !== null
        ? t(`components.admin.contracts.invChannel.${InvoiceChannel[contract.invoice_channel]}`)
        : t(`components.admin.contracts.invChannel.${InvoiceChannel[invChannel]}`);
    const oldStatus =
      contract.contract_status !== undefined && contract.contract_status !== null
        ? t(`components.admin.contracts.status.${ContractStatus[contract.contract_status]}`)
        : t(`components.admin.contracts.status.${ContractStatus[status]}`);
    const oldAdmin = contract.primary_manager ? contract.primary_manager : pManager;

    toModalString(t("components.admin.contracts.name"), modalArr, name, contract.name);
    toModalString(t("global.generalWords.address"), modalArr, address, contract.street_address);

    toModalString(t("global.generalWords.addressSpecifier"), modalArr, addressSpecifier, contract.address_specifier);
    toModalString(t("components.admin.contracts.zip"), modalArr, zip, contract.zip);
    toModalString(t("components.admin.contracts.city"), modalArr, city, contract.city);
    toModalString(t("components.admin.contracts.country"), modalArr, country, contract.country);
    toModalString(t("components.admin.contracts.bId"), modalArr, bId, contract.business_id);

    toModalString(
      t("components.admin.contracts.invMethod.method"),
      modalArr,
      t(`components.admin.contracts.invMethod.${InvoicingMethod[invMethod]}`),
      oldInvMethod
    );
    if (invMethod === 2) {
      toModalString(
        t("components.admin.contracts.bank"),
        modalArr,
        formatDisplayIBAN(bank),
        formatDisplayIBAN(contract.bank_account ? contract.bank_account : "")
      );
      toModalString(t("components.admin.contracts.vat"), modalArr, vat, `${contract.vat ? contract.vat : ""}`);
      toModalString(t("components.admin.contracts.paymentRef"), modalArr, paymentRef, contract.payment_reference);
    }

    toModalStringArray(
      "Report receivers",
      modalArr,
      reportReceivers,
      contract.report_receivers ? contract.report_receivers.trim().split(",") : [""]
    );

    toModalString(
      t("components.admin.contracts.invChannel.channel"),
      modalArr,
      t(`components.admin.contracts.invChannel.${InvoiceChannel[invChannel]}`),
      oldInvChannel
    );

    if ((contract.invoice_channel === 3 && invChannel !== 3) || invChannel === 3) {
      toModalString(t("components.admin.contracts.einvoiceOperator"), modalArr, eOperator, contract.einvoice_operator);
      toModalString(t("components.admin.contracts.einvoiceAddress"), modalArr, eAddress, contract.einvoice_address);
      toModalString(t("components.admin.contracts.einvoiceEdi"), modalArr, eEdi, contract.einvoice_edi);
    }

    toModalString(t(`components.admin.contracts.orderRef`), modalArr, orderRef, contract.invoice_reference);

    toModalString(t("components.admin.contracts.billingAddress"), modalArr, billingAddress, contract.billing_address);

    toModalString(
      t(`components.admin.contracts.status.status`),
      modalArr,
      t(`components.admin.contracts.status.${ContractStatus[status]}`),
      oldStatus
    );

    toModalString(t("components.admin.contracts.primary"), modalArr, pManager, `${oldAdmin}`);

    toModalString(t("components.admin.contracts.notes"), modalArr, notes, contract.notes);

    toModalString(
      t("components.admin.contracts.terms"),
      modalArr,
      displayDate,
      strOrDef(contract.terms_accepted).split("T")[0]
    );

    setChanges(modalArr);

    if (modalArr.length === 0) setShowModal(false);
    else setShowModal(true);
  };

  async function addContractRootArea(): Promise<void> {
    setAreaNameAlert(false);
    if (areaName.length === 0) {
      setAreaNameAlert(true);
      return;
    }
    const data = {
      name: areaName,
      contract_id: contract.contract_number!,
      default_price: contract.default_chargepoint_price,
    };
    const ret = await addDefaultArea(parent.id!, data);
    if (ret.success) {
      timer(setAddAreaSuccess, 2000);
      setInternalRunUF(true);
    } else {
      timer(setAddAreaFail);
      logger(ret.data, LogLevel.ERROR);
    }
  }

  return (
    <StyledContract className="top-level-component">
      <Container>
        <Button className="mb-3" variant="secondary" onClick={toggleView}>
          {t("global.buttons.back")}
        </Button>
        {showView === -1 ? ( //showView is -1 when the component is initialized, display "Loading...".
          <>
            <h2 className="align-self-center">{t("global.view.loading")}</h2>
          </>
        ) : showView === 0 ? ( //if showView is 0, an error occured when retrieving the data from the backend.
          <h2 className="align-self-center">{t("global.view.noAccess")}</h2>
        ) : (
          <>
            <Row>
              {isFormAlert() && (
                <Col>
                  <Alert variant="danger" ref={alertRef}>
                    {/**TODO Translate */}
                    <p>Please address the following:</p>
                    <ul>
                      {nameAlert && <li>The contract should have a name</li>}
                      {bIdAlert && <li>Business ID should not be empty</li>}
                      {vatAlert && <li>VAT should have a value</li>}
                      {pManagerAlert && <li>A primary manager for the contract should be specified</li>}
                      {areaNameAlert && <li>The area should be given a name</li>}
                      {eInvoiceAlert && (
                        <li>E-invoice fields must be populated when the chosen invoice channel is E-invoice</li>
                      )}
                      {invoiceAlert && (
                        <li>Invoice fields must be poulated when the chosen invoicing method is Invoicing</li>
                      )}
                      {locationAlert && <li>Street address, zip, city and country fields must be populated</li>}
                    </ul>
                  </Alert>
                </Col>
              )}
            </Row>
            <>
              <InputGroup className="mb-3">
                <InputGroup.Text>Contract Id</InputGroup.Text>
                <FormControl type="text" value={contract.contract_number} readOnly />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Text>{t("components.admin.contracts.name")} (*)</InputGroup.Text>
                <FormControl
                  type="text"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  data-cy="name"
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text>{t("components.admin.contracts.bId")}</InputGroup.Text>
                <FormControl
                  type="text"
                  value={bId}
                  onChange={(event) => setBId(event.target.value)}
                  data-cy="business-id"
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text>{t("global.generalWords.streetAddress")} (*)</InputGroup.Text>
                <FormControl
                  type="text"
                  value={address}
                  onChange={(event) => setAddress(event.target.value)}
                  data-cy="address"
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text>{t("global.generalWords.addressSpecifier")}</InputGroup.Text>
                <FormControl
                  type="text"
                  value={addressSpecifier}
                  onChange={(event) => setAddressSpecifier(event.target.value)}
                  data-cy="address"
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text>{t("components.admin.contracts.zip")} (*)</InputGroup.Text>
                <FormControl type="text" value={zip} onChange={(event) => setZip(event.target.value)} data-cy="name" />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text>{t("components.admin.contracts.city")} (*)</InputGroup.Text>
                <FormControl
                  type="text"
                  value={city}
                  onChange={(event) => setCity(event.target.value)}
                  data-cy="name"
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text>{t("components.admin.contracts.country")} (*)</InputGroup.Text>
                <FormControl
                  type="text"
                  value={country}
                  onChange={(event) => setCountry(event.target.value)}
                  data-cy="name"
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <DropdownButton
                  title={`${t("components.admin.contracts.invMethod.method")}`}
                  data-cy="invoicing-method-selector"
                >
                  {Object.entries(InvoicingMethod).map(([k, v], idx) => {
                    if (!isDev && v === "test") return null;
                    return (
                      <Dropdown.Item
                        as="button"
                        key={idx}
                        onClick={() => setInvMethod(Number(k) as Key<typeof InvoicingMethod>)}
                      >
                        {t(`components.admin.contracts.invMethod.${v}`)}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
                <FormControl
                  type="text"
                  value={t(`components.admin.contracts.invMethod.${InvoicingMethod[invMethod]}`)}
                  disabled={true}
                  data-cy="invoicing-method"
                />
              </InputGroup>
              {invMethod === 2 && (
                <>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>{t("components.admin.contracts.vat")}</InputGroup.Text>
                    <FormControl
                      type="text"
                      value={vat}
                      onChange={(event) => setVat(event.target.value)}
                      data-cy="vat"
                    />
                  </InputGroup>

                  <InputGroup className="mb-3">
                    <InputGroup.Text>{t("components.admin.contracts.bank")}</InputGroup.Text>
                    <FormControl
                      type="text"
                      value={bank}
                      onChange={(event) => setBank(event.target.value)}
                      data-cy="bank-account"
                    />
                  </InputGroup>

                  <InputGroup className="mb-3">
                    <InputGroup.Text>{t("components.admin.contracts.paymentRef")}</InputGroup.Text>
                    <FormControl
                      type="text"
                      value={paymentRef}
                      onChange={(event) => setPaymentRef(event.target.value)}
                      data-cy="payment-reference"
                    />
                  </InputGroup>
                  <DynamicListForm
                    header={t("components.admin.contracts.receivers")}
                    array={reportReceivers}
                    setArray={setReportReceivers}
                  />
                </>
              )}
              <InputGroup className="mb-3">
                <DropdownButton
                  title={`${t("components.admin.contracts.invChannel.channel")}`}
                  data-cy="invoice-channel-selector"
                  id="invoice-channel-selector"
                >
                  {Object.entries(InvoiceChannel).map(([k, v], idx) => {
                    return (
                      <Dropdown.Item
                        as="button"
                        key={idx}
                        onClick={() => setInvChannel(Number(k) as Key<typeof InvoiceChannel>)}
                      >
                        {t(`components.admin.contracts.invChannel.${v}`)}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
                <FormControl
                  type="text"
                  value={t(`components.admin.contracts.invChannel.${InvoiceChannel[invChannel]}`)}
                  disabled={true}
                  data-cy="invoice-channel"
                />
              </InputGroup>
              {invChannel === 3 ? (
                <>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>{t("components.admin.contracts.einvoiceOperator")} (*)</InputGroup.Text>
                    <FormControl
                      type="text"
                      value={eOperator}
                      onChange={(event) => setEOperator(event.target.value)}
                      data-cy="einvoice-operator"
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>{t("components.admin.contracts.einvoiceAddress")} (*)</InputGroup.Text>
                    <FormControl
                      type="text"
                      value={eAddress}
                      onChange={(event) => setEAddress(event.target.value)}
                      data-cy="einvoice-address"
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>{t("components.admin.contracts.einvoiceEdi")}</InputGroup.Text>
                    <FormControl
                      type="text"
                      value={eEdi}
                      onChange={(event) => setEEdi(event.target.value)}
                      data-cy="einvoice-edi"
                    />
                  </InputGroup>
                </>
              ) : invChannel === 1 ? (
                <InputGroup className="mb-3">
                  <InputGroup.Text>{t("components.admin.contracts.invoice_email")} (*)</InputGroup.Text>
                  <FormControl
                    type="text"
                    value={invEmail}
                    onChange={(event) => setInvEmail(event.target.value)}
                    data-cy="einvoice-edi"
                  />
                </InputGroup>
              ) : null}
              <InputGroup className="mb-3">
                <InputGroup.Text>{t("components.admin.contracts.orderRef")}</InputGroup.Text>
                <FormControl
                  type="text"
                  value={orderRef}
                  onChange={(event) => setOrderRef(event.target.value)}
                  data-cy="order-reference"
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text>{t("components.admin.contracts.billingAddress")}</InputGroup.Text>
                <FormControl
                  type="text"
                  value={billingAddress}
                  onChange={(event) => setBillingAddress(event.target.value)}
                  data-cy="billing-address"
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <DropdownButton
                  title={t("components.admin.contracts.status.status")}
                  data-cy="contract-status-selector"
                >
                  {Object.entries(ContractStatus).map(([k, v], idx) => {
                    return (
                      <Dropdown.Item
                        as="button"
                        key={idx}
                        onClick={() => setStatus(Number(k) as Key<typeof ContractStatus>)}
                      >
                        {t(`components.admin.contracts.status.${v}`)}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
                <FormControl
                  type="text"
                  value={t(`components.admin.contracts.status.${ContractStatus[status]}`)}
                  disabled={true}
                  data-cy="contract-status"
                />
              </InputGroup>
              <InputGroup>
                {/*Form field for the start date. It's an html date type*/}
                <InputGroup.Text className="mb-3">{t("components.admin.contracts.terms")}</InputGroup.Text>
                <FormControl
                  className="date-time-picker mb-3"
                  type="date"
                  value={displayDate}
                  onChange={(event) => handleDate(event.target.value)}
                  data-cy="terms-accepted"
                />
              </InputGroup>
              {admin ? (
                <InputGroup className="mb-3">
                  <InputGroup.Text>{`${t("components.admin.contracts.primary")}`}</InputGroup.Text>
                  <FormControl
                    type="text"
                    value={pManager}
                    placeholder="@"
                    disabled={!addMode}
                    data-cy="primary-manager"
                    onChange={(event) => setPManager(event.target.value)}
                  />
                </InputGroup>
              ) : null}
              <InputGroup className="mb-3">
                <InputGroup.Text>{`${t("components.admin.contracts.defaultCpPrice")}`}</InputGroup.Text>
                <FormControl
                  type="text"
                  value={defaultCpPrice}
                  onChange={(event) => {
                    if (isNotDigitOrComma.test(event.target.value)) return defaultCpPrice;
                    setDefaultCpPrice(event.target.value);
                  }}
                  onBlur={(event) => allowDotAndComma(event.target.value)}
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text>{t("components.admin.contracts.notes")}</InputGroup.Text>
                <FormControl
                  type="text"
                  value={notes}
                  onChange={(event) => setNotes(event.target.value)}
                  data-cy="notes"
                />
              </InputGroup>
              <Row className="mb-3">
                {errorAlert && (
                  <Col>
                    <Alert variant="danger" ref={alertRef}>
                      Errors occured, please try again or contact support@wattery.io
                    </Alert>
                  </Col>
                )}
                {successAlert && (
                  <Col>
                    <Alert variant="success" ref={alertRef}>
                      Successfully updated contract
                    </Alert>
                  </Col>
                )}
              </Row>
              <AddEditModal
                showChangeModal={showChanges}
                disabledFields={disabledFields}
                showChange={showModal}
                setShowChange={setShowModal}
                makeChanges={submit}
                changes={changes}
                body={t("components.admin.contracts.modal")}
              />
              <br />
              <br />
              {/* We want to hide these since there are no contract root areas and we dont want to create an area
                separately from the contract, since the contract_id has to be inserted in to newly added area
            */}
              {!addMode && hasRootArea ? (
                <>
                  <Row xs={1} sm={1} className="mb-3">
                    <Col>
                      <h4>{t("components.admin.contracts.contractRoots")}</h4>

                      <ListGroup className="subareas-list">
                        {areas.map((child, idx) => {
                          //The reason for this check is that the absolute root area has an ID of 0
                          //(the first element in the child array is the child id), and we don't want
                          //to display absolute root area as its own subarea.
                          if (child.id !== 0) {
                            return (
                              //dont like this, find out why it maps the children this way
                              <ListGroup.Item key={idx} data-cy="subarea-row">
                                <Link to={`/area/${child.id}`}>{child.name}</Link>
                              </ListGroup.Item>
                            );
                          }
                          return null;
                        })}
                      </ListGroup>
                    </Col>
                  </Row>
                  <br />
                  {addAreaSuccess ? <Alert variant="success">Area added</Alert> : null}
                  {addAreaFail ? <Alert variant="success">Couldn't add area</Alert> : null}
                  <div className="mb-3">
                    <h4>{t("components.admin.contracts.createArea")}</h4>
                    <AreaNameAndParent
                      name={areaName}
                      setName={setAreaName}
                      parent={parent!}
                      setParent={setParent}
                      disabledFields={disabledFields}
                      areas={areas}
                    />
                  </div>
                  <Button className="mb-3" onClick={addContractRootArea}>
                    {t("global.buttons.create")}
                  </Button>
                </>
              ) : null}
            </>
          </>
        )}
      </Container>
    </StyledContract>
  );
};
