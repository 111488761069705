import { tokenRenewingGet, tokenRenewingOcppPost } from "./lib/tokenRenewingRequest";

// OCPP API

export const startNewTransaction = async (data: any) =>
  await tokenRenewingOcppPost("/api/transactions/remoteStart", data);

export const createOptimizationSchedule = async (data: any) =>
  await tokenRenewingOcppPost("/api/transactions/createOptimizationSchedule", data);

export const sendRemoteStop = async (data: any) => await tokenRenewingOcppPost("/api/transactions/remoteStop", data);

export const sendForceStop = async (data: any) => await tokenRenewingOcppPost("/api/transactions/forceStop", data);

export const rebootChargepoint = async (rebootData: any) =>
  await tokenRenewingOcppPost("/api/transactions/reset", rebootData);

export const unlockConnector = async (connectorData: any) =>
  await tokenRenewingOcppPost("/api/transactions/unlock", connectorData);

// Other

export const checkChargepointAvailability = async (charge_point_id: string) =>
  await tokenRenewingGet<any>(`/api/transactions/inUse/${charge_point_id}`);

export const checkIfCardRequired = async (charge_point_id: string) =>
  await tokenRenewingGet<any>(`/api/transactions/cardRequired/${charge_point_id}`);

export const getRecentChargepointIds = async () => await tokenRenewingGet<any>("/api/user/chargepoints/recent/id");

export const getRecentChargepoints = async () => await tokenRenewingGet<any>("/api/user/chargepoints/recent");
