import { ActiveTransaction, CompletedTransaction } from "../model/Classes/Transaction";
import { SpotTransactionHistory } from "../model/Classes/SpotPrice";
import { tokenRenewingGet } from "./lib/tokenRenewingRequest";

export const getMyTransactionsCompleted = async () =>
  await tokenRenewingGet<CompletedTransaction[]>("/api/myTransactions");

export const getMyTransactionsActive = async () =>
  await tokenRenewingGet<ActiveTransaction[]>("/api/myTransactions/active");

export const getMyMonthlyTransactions = async (year: number, month: string) =>
  await tokenRenewingGet<CompletedTransaction[]>(`/api/myTransactions/${year}/${month}`);

export const getMyMonthlySpotPriceTransactions = async (year: number, month: string) =>
  await tokenRenewingGet<SpotTransactionHistory[]>(`/api/myTransactions/spotPrice/${year}/${month}`);

export const getMyYearlyTransactions = async (year: number) =>
  await tokenRenewingGet<CompletedTransaction[]>(`/api/myTransactions/${year}`);

export const getSpotPricePendingCount = async () =>
  await tokenRenewingGet<number>("/api/myTransactions/pending-transaction-count");
