import { useEffect } from "react";
import { Route, RouteProps, useHistory } from "react-router-dom";
import { jwtDecode, JwtPayload } from "jwt-decode";
import Cookies from "js-cookie";
import { StateHandler } from "../../model/Utilities/Types";
import User from "../../model/Classes/User";
import { Nullable } from "../../model/Utilities/Types";

import { renewTokens } from "../../services/authService";

interface ProtectedRouteProps extends RouteProps {
  children: React.ReactNode;
  setUser: StateHandler<Nullable<User>>;
}

const decodedValidTokenOrNull = (token?: string): JwtPayload | null => {
  if (token) {
    try {
      const decodedToken = jwtDecode<JwtPayload>(token);
      if (decodedToken.exp && new Date(decodedToken.exp * 1000) > new Date()) {
        return decodedToken;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return null;
};

const ProtectedRoute = ({ children, setUser, ...routeProps }: ProtectedRouteProps) => {
  const history = useHistory();

  useEffect(() => {
    const checkTokenExpiry = async (accessToken?: string, refreshToken?: string) => {
      let decodedAccessToken = decodedValidTokenOrNull(accessToken);

      if (!decodedAccessToken && refreshToken) {
        const decodedRefreshToken = decodedValidTokenOrNull(refreshToken);
        if (decodedRefreshToken) {
          await renewTokens();
          decodedAccessToken = decodedValidTokenOrNull(Cookies.get("access_token"));
        }
      }

      if (decodedAccessToken) {
        const { iss, sub, aud, exp, nbf, iat, jti, ...userData } = decodedAccessToken;
        setUser(userData as User);
      } else {
        // Invalid token, redirect to login
        history.push({
          pathname: "/login",
          state: { from: history.location },
        });
      }
    };

    checkTokenExpiry(Cookies.get("access_token"), Cookies.get("refresh_token"));
  }, [history]);

  return <Route {...routeProps}>{children}</Route>;
};

export default ProtectedRoute;
