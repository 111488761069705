import StructuredResponse from "../model/Classes/StructuredResponse";
import { ActiveTransaction } from "../model/Classes/Transaction";
import { StateHandler } from "../model/Utilities/Types";
import { getMyTransactionsActive } from "../services/myTransactionsService";
import { getAllActiveTransactionsForChargepoint, searchAllActiveTransactions } from "../services/reportingService";
import { logger } from "./logger";
import { timer } from "./timer";

/**
 * Utility function for refreshing active transactions. The function takes as the first paramater
 * a string indicating which component calls the function:
 * - "area" for the SingleArea component
 * - "chargepoint" for the Chargepoint component
 * - "reporting" for the Reporting component
 * - "landingPage" for the LandingPage component
 * - "myTransactions" for the MyTransactions component
 *
 * Depending on the first parameter, a corresponding service function is called (each component
 * has a specific service function). Some need the area id and/or chargepoint id, hence we need
 * those as parameters as well.
 *
 * The function starts the loading animation by calling setSearching(true), and then tries to
 * fetch the data from the backend. If it fails, it catches the error and logs it and stop the
 * loading animation. If successful, it calls setActiveTransactions(data from backend) and stops
 * the loading animation. Finally, it shows a checkmark (setSearchComplete(true)) for two seconds.
 * @param {*} forWhat
 * @param {*} areaId
 * @param {*} cpId
 * @param {*} setActiveTransactions
 * @param {*} setSearching
 * @param {*} setSearchComplete
 */
export const refreshActiveTransactions = async (
  forWhat: string,
  areaId: number | null | undefined,
  cpId: string | null,
  setActiveTransactions: StateHandler<ActiveTransaction[]>,
  setSearching: StateHandler<boolean>,
  setSearchComplete: StateHandler<boolean>,
  setExecutionTimeExceeded?: StateHandler<boolean>
) => {
  //Start the animation
  setSearching(true);
  //Fetch the data from the backend with a service function depending on the
  //forWhat parameter

  let res: StructuredResponse<ActiveTransaction[]> | undefined;
  switch (forWhat) {
    case "area":
    case "chargepoint":
      if (!areaId || !cpId) {
        setSearching(false);
        return;
      }
      res = await getAllActiveTransactionsForChargepoint({
        areaId: Number(areaId),
        cpId: cpId,
      });
      break;
    case "reporting":
      if (!areaId) {
        setSearching(false);
        return;
      }
      res = await searchAllActiveTransactions({ area_id: Number(areaId) });
      break;
    case "landingPage":
    case "myTransactions":
    default:
      res = await getMyTransactionsActive();
      break;
  }

  if (res.success) {
    setActiveTransactions(res.data);
    setSearching(false); //Stop the loading animation
    timer(setSearchComplete, 2000); //Show a checkmark for 2 seconds
  } else {
    //This is absolutely horrific
    const e = res.data as any;
    //Some error was caught
    //Log the error and stop the loading animation
    if (e.response && e.response.data.errno === 3024) timer(setExecutionTimeExceeded!);
    logger(e);
    setSearching(false); //Stop the loading animation
  }
};

export async function refreshTable<TSource, TData>(
  data: TData,
  asyncCallback: (data: TData) => Promise<StructuredResponse<TSource[]>>,
  setTableData: StateHandler<TSource[]>,
  setSearching: StateHandler<boolean>,
  setSearchComplete: StateHandler<boolean>,
  errorFunc: (error: any) => boolean,
  setErrorNotification: StateHandler<boolean>
): Promise<void> {
  setSearching(true);
  const res = await asyncCallback(data);

  if (res.success) {
    setTableData(res.data);
    setSearching(false);
    timer(setSearchComplete, 2000);
  } else {
    if (errorFunc(res.data)) {
      timer(setErrorNotification);
    }
    logger(res.data);
    setSearching(false);
  }
}
