import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import { Button, Container, Row, Col, Tabs, Tab, Alert } from "react-bootstrap";
import { StyledSingleArea } from "../SingleArea.styled";
import { SingleAreaGeneral, SingleAreaSubareas } from "./SingleAreaComponents";
import { SingleAreaChargepoints } from "./SingleAreaChargepoints";
import { SingleAreaMeterGraph } from "./AreaMeterGraph";
import ActiveTransactions from "../../../public/Transactions/ActiveTransactions";
import AuthorizeUser from "../Settings/AuthorizeUser";
import { getAreaBranches } from "../../../../services/areaService";

import { getAuthorizedUsers } from "../../../../services/areaService";
import { forceStop } from "../../../../utils/forceStop";
import { remoteStop } from "../../../../utils/remoteStop";
import { timer } from "../../../../utils/timer";
import EditArea from "../Settings/EditArea";
import AddArea from "../Settings/AddArea";
import AddChargepoint from "../Settings/AddChargepoint";
import editIcon from "../../../../resources/editIcon.svg";
import { refreshActiveTransactions } from "../../../../utils/refreshActiveTransactions";
import { logger } from "../../../../utils/logger";
import { getChargepoints } from "../../../../services/areaService";
import { pad, makeDate, parseISOString, decodeAndMakeDate } from "../../../../utils/dates";
import { getAreaMeterValues } from "../../../../services/areaService";
import { getAreaMeters } from "../../../../services/areaService";
import CpFull from "../../../../model/Classes/Chargepoint";
import { ActiveTransaction } from "../../../../model/Classes/Transaction";
import User from "../../../../model/Classes/User";
import AreaSingle, { AccessType } from "../../../../model/Classes/Area";
import { StateHandler } from "../../../../model/Utilities/Types";
import AreaMeterValue from "../../../../model/Classes/AreaMeterValue";
import i18N from "../../../../i18n";
import { useTranslation } from "react-i18next";
import StructuredResponse from "../../../../model/Classes/StructuredResponse";
import AreaMeter from "../../../../model/Classes/AreaMeter";
import { getUserRootAreas } from "../../../../services/areaService";
import { useOnTabClicked } from "../../../../hooks";
import { getAreaContract } from "../../../../services/adminService";
import { Contract } from "../../../../model/Classes/Contract";

import { PrintableChargepointNoLoginSticker } from "../../Chargepoint/ChargepointNoLoginSticker";
import ReactToPrint from "react-to-print";

/**
 * Component responsible for displaying the single area page. The component contains a tab view for general info,
 * active transactions, and authorization.
 *
 * The reason why this component has so many props is due to the fact that the different tabs are rendered with
 * the help of the components defined in SingleAreaComponents.js. The components require a lot of information +
 * helper functions + states + state handlers, hence all the props.
 */

declare interface SingleAreaProps {
  user: User;
  area: AreaSingle;
  toggleEdit: () => void;
  toggleAddCp: (thing: boolean) => void;
  parent: AreaSingle;
  chargePoints: CpFull[];
  authorizedUsers: User[];
  setShowLoading: StateHandler<boolean>;
  setShowCheckmark: StateHandler<boolean>;
  showLoading: boolean;
  showCheckmark: boolean;
  history: any;
  toggleAddDirectArea: (thing?: boolean) => void;
  refreshAuthorizedUsers: () => Promise<void>;
  CPDeleted: boolean;
  CPMoved: boolean;
  CPAdded: boolean;
  areaAdded: boolean;
  filterValue: number;
  setFilterValue: StateHandler<number>;
  areaMeterValues: AreaMeterValue[];
  showGraph: number;
  interval: number;
  intervalMinValue: number;
  startDate: string;
  startTime: string;
  stopDate: string;
  stopTime: string;
  setInterval: StateHandler<number>;
  setStartDate: StateHandler<string>;
  setStartTime: StateHandler<string>;
  setStopDate: StateHandler<string>;
  setStopTime: StateHandler<string>;
  searchAreaMeterValues: () => Promise<void>;
  invalidDates: boolean;
  activeEventKey: string;
  setActiveEventKey: StateHandler<string>;
  contract?: Contract;
  isReporting: number;
  setIsReporting: StateHandler<number>;
  allAreas: AreaSingle[];
  pricingType: number;
  setPricingType: StateHandler<number>;
  allChargePoints: CpFull[];
}

const SingleArea = ({
  activeEventKey,
  setActiveEventKey,
  user,
  area,
  toggleEdit,
  toggleAddCp,
  parent,
  chargePoints,
  authorizedUsers,
  showLoading,
  showCheckmark,
  history,
  toggleAddDirectArea,
  refreshAuthorizedUsers,
  CPDeleted,
  CPMoved,
  CPAdded,
  areaAdded,
  filterValue,
  setFilterValue,
  areaMeterValues,
  showGraph,
  interval,
  intervalMinValue,
  startDate,
  startTime,
  stopDate,
  stopTime,
  setInterval,
  setStartDate,
  setStartTime,
  setStopDate,
  setStopTime,
  searchAreaMeterValues,
  invalidDates,
  contract,
  setShowCheckmark,
  setShowLoading,
  isReporting,
  setIsReporting,
  allAreas,
  pricingType,
  setPricingType,
  allChargePoints,
}: SingleAreaProps) => {
  /**
   * The component returns:
   * 1) The title of the area + edit button
   * 2) Tab to navigate between the general page (contains general info, subareas, and chargepoints), active transactions,
   *    authorization (contains functionality for authorizing users to this area, and users that area directly and
   *    indirectly authorized to this area), and usage (contains area meter value graph)
   * 3) Button to navigate back to all areas
   *
   * The different tabs contain components defined in the SingleAreaComponent.js file.
   */
  const [allActiveTransactions, setAllActiveTransactions] = useState<ActiveTransaction[]>([]);
  const [filteredActiveTransactions, setFilteredActiveTransactions] = useState<ActiveTransaction[]>([]);
  const [forceStopSuccess, setForceStopSuccess] = useState(false); //state for succesfully force stopping an active transaction alert
  const [forceStopError, setForceStopError] = useState(false); //state for failed force stopping of an active transaction alert
  const [forceStopSuccessButRefreshActive, setForceStopSuccessButRefreshActive] = useState(false); //state for succesfully force stopping an active transaction, but failed refreshing alert
  const [remoteStopSuccess, setRemoteStopSuccess] = useState(false); //state for succesfully remote stopping an active transaction alert
  const [remoteStopError, setRemoteStopError] = useState(false); //state for failed remote stopping of an active transaction alert
  const [remoteStopSuccessButRefreshActive, setRemoteStopSuccessButRefreshActive] = useState(false); //state for succesfully remote stopping an active transaction, but failed refreshing alert
  const [executionTimeExceedAlert, setExecutionTimeExceededAlert] = useState(false);

  const handleKeyChange = (eventValue: any) => {
    setActiveEventKey(eventValue);
  };

  const noLoginStickerRef = useRef(null);

  useOnTabClicked(
    activeEventKey,
    ["activeTransactions"],
    async () => {
      if (allActiveTransactions.length === 0) await refresh();
    },
    []
  );

  useEffect(() => {
    if (filterValue === 0) {
      setFilteredActiveTransactions(allActiveTransactions);
    } else if (filterValue === 1) {
      const filtered = allActiveTransactions.filter(
        //The area is obviously never undefined if we get here but we have to specify it for the compiler
        //Since it is initialised in a useEffect hook and not upon entering component
        (active: ActiveTransaction) => active.name === area!.name
      );
      setFilteredActiveTransactions(filtered);
    }
  }, [filterValue, area, allActiveTransactions]);

  /**
   * Asynchronous helper function for refreshing all the active transactions (refresh button).
   * The function calls the utility function refreshActiveTransactions which handles the
   * functionality for refreshing active transactions.
   */
  const refresh = async () => {
    //Call the utility function refresActiveTransactions.
    //We pass the string "area" as the first parameter to indicate that we're calling this
    //function for the SingleArea component. Additionally, we pass the area id as the second parameter
    //and null as the third parameter since we don't need to specify a chargepoint id
    refreshActiveTransactions(
      "area",
      area.id,
      null,
      setAllActiveTransactions,
      setShowLoading,
      setShowCheckmark,
      setExecutionTimeExceededAlert
    );
  };

  /**
   * Asynchronous helper function used to call the forceStop utility function when the user
   * wants to force stop an active transaction.
   * @param {*} activeId the id of the active transaction
   * @param {*} areaId the id of the area the user is currently at
   */
  const callForceStop = async (activeId: number, areaId?: number) => {
    forceStop(
      activeId,
      null,
      "singleArea",
      setForceStopSuccess,
      setForceStopError,
      setForceStopSuccessButRefreshActive,
      setAllActiveTransactions,
      refresh,
      areaId
    );
  };

  /**
   * Asynchronous helper function used to call the remoteStop utility function when the user
   * wants to remote stop an active transaction.
   * @param {*} activeId the id of the active transaction
   * @param {*} areaId the id of the area the user is currently at
   */
  const callRemoteStop = async (activeId: number, cpId?: string) => {
    await remoteStop(
      activeId,
      "singleArea",
      setRemoteStopSuccess,
      setRemoteStopError,
      setRemoteStopSuccessButRefreshActive,
      setAllActiveTransactions,
      area.id,
      cpId
    );
    await refresh();
  };

  const { t } = useTranslation("common", {
    i18n: i18N,
  });

  const printableChargePointsCount = allChargePoints.filter(
    (cp) => cp.access_type && cp.access_type >= AccessType.privateAndPublic
  ).length;

  return (
    <>
      {/*Row for title and edit button*/}
      <Row>
        <h1>
          {area.name}
          <Button variant="outline-ligth" data-cy="areaEditButton">
            <img src={editIcon} alt="Edit icon" onClick={toggleEdit} />
          </Button>
        </h1>
      </Row>

      {/*Row for the different tabs*/}
      <Row>
        <Tabs activeKey={activeEventKey} defaultActiveKey="general" id="" className="mb-3" onSelect={handleKeyChange}>
          {/*General tab*/}
          <Tab eventKey="general" title={t("components.area.static.singleArea.header.tabHeader")}>
            <Row>
              {CPDeleted ? (
                <Alert key="deletedCp" variant="success">
                  {t("global.alert.success.cpDelete")}
                </Alert>
              ) : null}
              {CPMoved ? (
                <Alert key="movedCp" variant="success">
                  {t("global.alert.success.cpMove")}
                </Alert>
              ) : null}
              {areaAdded ? (
                <Alert key="areaAdded" variant="success">
                  {t("global.alert.success.areaAsSubArea")}
                </Alert>
              ) : null}
              {CPAdded ? (
                <Alert key="cpAdded" variant="success">
                  {t("global.alert.success.cpAdd")}
                </Alert>
              ) : null}
            </Row>
            <Row id="general-info" data-cy="general-info">
              {/*Component for showing the general info table*/}
              <SingleAreaGeneral
                area={area}
                parent={parent}
                isReporting={isReporting}
                allAreas={allAreas}
                user={user}
              />
            </Row>
            <Row>
              {/*Component for showing the subareas table*/}
              <SingleAreaSubareas area={area} />
              <Col xs="4" sm="4">
                <Button
                  className="mb-3"
                  onClick={() => {
                    toggleAddDirectArea();
                  }}
                  id="addAreaButtonSingleArea"
                >
                  {t("global.buttons.add.area")}
                </Button>
              </Col>
            </Row>
            <Row>
              {/*Component for showing the chargepoints table*/}
              <SingleAreaChargepoints chargePoints={chargePoints} area={area} />
            </Row>
            <Row>
              <Col xs="4" sm="4">
                <Button className="mb-3 mt-3" onClick={() => toggleAddCp(false)} data-cy="add-chargepoint-button">
                  {t("global.buttons.add.cp")}
                </Button>
              </Col>
            </Row>
          </Tab>
          {/*Active transactions tab*/}
          <Tab eventKey="activeTransactions" title={t("components.activeTransactions.static.header.exist")}>
            <Row>
              {executionTimeExceedAlert ? (
                <Col>
                  <Alert variant="warning">{t("global.alert.failure.exectionTime")}</Alert>
                </Col>
              ) : null}
              {/*Component for showing the active transactions with its functionality*/}
              <ActiveTransactions
                forceStopSuccess={forceStopSuccess}
                forceStopError={forceStopError}
                forceStopSuccessButRefreshActive={forceStopSuccessButRefreshActive}
                remoteStopSuccess={remoteStopSuccess}
                remoteStopError={remoteStopError}
                remoteStopSuccessButRefreshActive={remoteStopSuccessButRefreshActive}
                activeTransactions={filteredActiveTransactions}
                showCheckmark={showCheckmark}
                showLoading={showLoading}
                callRemoteStop={callRemoteStop}
                callForceStop={callForceStop}
                user={user}
                forArea={{
                  filterValue: filterValue,
                  setFilterValue: setFilterValue,
                }}
                refresh={refresh}
              />
            </Row>
          </Tab>
          {/*Authorized users tab*/}
          <Tab eventKey="authorization" title={t("components.authorize.static.header")}>
            <Row>
              {/*Component for showing the authorized users with its functionality*/}
              <AuthorizeUser
                area={area}
                authorizedUsers={authorizedUsers}
                refreshAuthorizedUsers={refreshAuthorizedUsers}
              />
            </Row>
          </Tab>
          {/*Area meter graph tab*/}
          {area.has_meter !== 0 && (
            <Tab eventKey="areaMeterGraph" title={t("components.area.graph.header.title")}>
              <Row style={{ justifyContent: "center" }}>
                {/*Component for showing the area meter value graph with its functionality*/}
                <SingleAreaMeterGraph
                  area={area}
                  data={areaMeterValues}
                  showGraph={showGraph}
                  interval={interval}
                  intervalMinValue={intervalMinValue}
                  startDate={startDate}
                  startTime={startTime}
                  stopDate={stopDate}
                  stopTime={stopTime}
                  setInterval={setInterval}
                  setStartDate={setStartDate}
                  setStartTime={setStartTime}
                  setStopDate={setStopDate}
                  setStopTime={setStopTime}
                  searchAreaMeterValues={searchAreaMeterValues}
                  invalidDates={invalidDates}
                />
              </Row>
            </Tab>
          )}

          {/*We can't hide based on area.access_type, since there might be child areas that are public.*/}
          {printableChargePointsCount > 0 && (
            <Tab eventKey="noLoginStickers" title={t("components.area.static.noLoginStickers.header")}>
              {printableChargePointsCount > 3 && (
                <ReactToPrint
                  content={() => noLoginStickerRef.current}
                  trigger={() => (
                    <Button className="mb-3">{t("components.chargepoint.static.tabs.noLoginSticker.print")}</Button>
                  )}
                  documentTitle={area.name}
                ></ReactToPrint>
              )}

              <div ref={noLoginStickerRef}>
                {allChargePoints.map((chargePoint, i) => (
                  <PrintableChargepointNoLoginSticker chargepoint={chargePoint} width={400} key={i} />
                ))}
              </div>

              <ReactToPrint
                content={() => noLoginStickerRef.current}
                trigger={() => (
                  <Button className="mb-3">{t("components.chargepoint.static.tabs.noLoginSticker.print")}</Button>
                )}
                documentTitle={area.name}
              ></ReactToPrint>
            </Tab>
          )}
        </Tabs>
      </Row>
      {/*Row for showing the back button to all the areas. This is placed here so that the button is present
         in all the tabs*/}
      <Row>
        <Col xs="4" sm="4">
          <Button
            className="mb-3"
            id="goToAllAreas"
            onClick={() => history.push("/area", { area_id: area.id })}
            variant="secondary"
          >
            {t("components.area.button.all")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

/**
 * Component that is responsible for handling functioality related to a single area, as well display the correct
 * component (SingleArea, EditArea, AddArea, or AddChargepoint). The component does so with the help of states that
 * are toggle on if the corresponding component should be shown.
 *
 * useEffect 1: fetches all the data needed for the page. This includes information about all the areas the user
 * has access to, all the chargepoints, all active transactions for this area, and all (directly and indirectly) authorized
 * users to this area.
 *
 * useEffect 2: fetches all the data needed for the area meter value graph (24h). Also responsible for setting the initial value for
 * the showGraph state.
 *
 * useEffect 3: changes the minimum value for the time interval (if needed) for the area meter value graph whenever the html-date inputs changes.
 *
 * useEffect 4: checks if the user was redirected to this page with a state. If a state
 * is present, display an alert to the user with the corresponding information (e.g. when a chargepoint is deleted the user
 * is redirected to this page).
 *
 * useEffect 5: runs when the user changes the filter value for active transactions (will probably be removed when the table
 * is refactored to use react-bootstrap-table-v2)
 *
 * Authentication and authorization is checked at the backend. Since the first useEffect will always fetch data from the backend,
 * the backend authenticates and authorizes the user. If it passes, data is sent back to the frontend. If it doesn't (the
 * user doesn't have access to the area), an error message is displayed to the screen notifying that the user doesn't have
 * access to this area.
 *
 * NOTE! At the moment, when the frontend fetches data from the backend, and when the backend responds with an error (could be
 * the user is not authorized or an internal server error), an error message is displayed to the user informing the user
 * that they do not have access to this area. The error message should be checked and a correct error message should be displayed.
 * WILL BE IMPLEMENTED IN THE FUTURE!
 * @param {*} user the user trying to access the single area view
 * @param {*} history history object
 * @returns view for a single area or its settings
 */

declare interface SingleAreaViewProps {
  user: User;
  history: any;
}

const SingleAreaView = ({ user, history }: SingleAreaViewProps) => {
  const [editMode, setEditMode] = useState(false); //state for displaying the edit mode
  const [addCpMode, setAddCpMode] = useState(false); //state for displaying the "add chargepoint" mode
  const [addDirectArea, setAddDirectArea] = useState(false); //state for displaying the "add area" mode (directly add, so autofills the parent field)
  const [showView, setShowView] = useState(-1); //state for showing the loading screen, error screen, or single area screen
  const [showCheckmark, setShowCheckmark] = useState(false); //state for showing the checkmark (used for refresh button)
  const [showLoading, setShowLoading] = useState(false); //state for showing the loading animation (used for refresh button)
  const [runUseEffectAgain, setRunUseEffectAgain] = useState(false); //state for running the useEffect again
  const [runUseEffectMode, setRunUseEffectMode] = useState(0); //state for which mode to run the useEffect

  const [allAreas, setAllAreas] = useState<AreaSingle[]>([]); //state for all the areas
  const [area, setArea] = useState<AreaSingle>(); //state for the current area
  const [parent, setParent] = useState<AreaSingle>(); //state for the parent of the current area
  const [allChargePoints, setAllChargePoints] = useState<CpFull[]>([]); //state for all the chargepoints for this area
  //state for all the active transactions
  /* const [filteredActiveTransactions, setFilteredActiveTransactions] = useState<
    ActiveTransaction[]
  >([]); */ //state for all the active transactions that should be displayed
  const [filterValue, setFilterValue] = useState(0); //state for keeping track of the active transactions filter
  const [authorizedUsers, setAuthorizedUsers] = useState<User[]>([]); //state for all the authorized users (directly and inherited)

  const [areaMeterValues, setAreaMeterValues] = useState<AreaMeterValue[]>([]); //state for all the area meter values
  /* const [showCheckmarkMeterValues, setShowCheckmarkMeterValues] =
    useState(false); //state for showing the checkmark (used for fetching meter values)
  const [showLoadMeterValues, setShowLoadMeterValues] = useState(false); //state for showing the loading animation (used for fetching meter values) */
  const [showGraph, setShowGraph] = useState(-1); //state for determining how to show the area meter graph
  const [interval, setInterval] = useState(0); //state for the time interval for the area meter graph
  const [intervalMinValue, setIntervalMinValue] = useState(2);
  const [startDate, setStartDate] = useState(""); //state for the starting date in html date input format (YYYY-MM-DD)
  const [startTime, setStartTime] = useState(""); //state for the starting time in html date input format (HH:MM)
  const [stopDate, setStopDate] = useState(""); //state for the stopping date in html date input format (YYYY-MM-DD)
  const [stopTime, setStopTime] = useState(""); //state for the stopping time in html date input format (HH:MM)

  const [areaAdded, setAreaAdded] = useState(false); //state for the area added alert
  const [CPDeleted, setCPDeleted] = useState(false); //state for the charge point deleted alert
  const [CPMoved, setCPMoved] = useState(false); //state for the charge point moved alert
  const [CPAdded, setCPAdded] = useState(false); //state for the charge point added alert
  const [invalidDates, setInvalidDates] = useState(false); //state for an alert notifying the user if they input invalid dates for the area meter graph
  const [allMeters, setAllMeters] = useState<AreaMeter[]>([]);
  const [userRoot, setUserRoot] = useState<number[]>([]);
  const [activeEventKey, setActiveEventKey] = useState("general");
  const [contract, setContract] = useState<Contract>();
  const [isReporting, setIsReporting] = useState(0);
  const [spotPriceMargin, setSpotPriceMargin] = useState<string | number>("");
  const [userBiddingValue, setUserBiddingValue] = useState("");
  const [pricingType, setPricingType] = useState(0);

  /**
   * Helper function for toggling the view to edit mode
   */
  const toggleEdit = () => {
    setEditMode(!editMode);
  };
  const handleLimit = (event: any) => {};

  /**
   * Helper function for toggling the view for adding a chargepoint. If the parameter successfullAdd is
   * present, set an alert that notifies the user that adding a chargepoint was successful
   * @param successfullAdd boolean flag indicating if adding a chargepoint was successful
   */
  const toggleAddCp = (successfullAdd: boolean) => {
    if (successfullAdd) {
      timer(setCPAdded);
    }
    setAddCpMode(!addCpMode);
  };

  /**
   * Helper function for toggling the view for adding a subarea. If the parameter added is
   * present, set an alert that notifies the user that adding a subarea was successful
   * @param added boolean flag indicating if adding a subarea was successful
   */
  const toggleAddDirectArea = (added?: boolean) => {
    if (added) {
      timer(setAreaAdded);
    }
    setAddDirectArea(!addDirectArea);
  };

  /**
   * Helper function for running the useEffect again. The function initially sets the runUseEffectMode to the passed parameter,
   * since it determines if the showView state should be set to -1 in the beginning. or if data should be fetched.
   * Reasoning can be found in the comments for the useEffect
   * @param {*} mode what mode to run the useEffect in
   */
  const runUseEffect = (mode: number) => {
    setRunUseEffectMode(mode);
    setRunUseEffectAgain((s) => !s);
  };

  //the api address from where the code fetches area datas. There are two different ones:
  //1) /api/admin/area for an admin wanting all the areas in the application
  //2) /api/area for an area manager wanting all the areas the area manager has access to
  const apiAddress = "/api/area";

  //area id from the url parameter
  const id = Number(useParams<{ id?: string }>().id);

  /**
   * On each rerender, fetch all the areas, chargepoints, active transactions, and authorized users for this area.
   *
   * All the areas are sorted in alphabetical order.
   *
   * If the user doesn't have access to the area, an error message will be displayed to the screen notifying the user
   * that they do not have access to the area.
   *
   * The dependency array also includes another a state runUseEffectAgain that is directly not used in the function. This
   * state is changed whenever the function runUseEffect is called.
   *
   * If the state runUseEffectMode is anything but 1, the hook will set the showView state to -1 (renders the 'Loading...' screen).
   * This is needed since at times when we fetch data from the backend, we don't want to change the view since it prevents
   * alerts to be shown. E.g. when the user updates the settings for the area, we want to fetch the updated data from the backend.
   * If we would render the 'Loading...' screen before the data has been fetched, the previously rendered alert would disappear.
   * Additionally, if the runUseEffectMode is -1 it wont fetch data (only set when deleting area).
   *
   * NOTE! At the moment, if an error occurs from the backend (e.g. the user doesn't have access, or an internal server
   * error), the function doesn't check what kind of error it is. I.e., if an internal server error occurs, the user
   * is told that they do not have access to the area. WILL BE CHANGED IN THE FUTURE!
   */
  useEffect(() => {
    if (runUseEffectMode !== 1) setShowView(-1);
    setParent(undefined);

    /**
     * Asynchronous helper function for the useEffect that fetches all the areas, chargepoints, active transactions,
     * and authorized users from the backend, and updates the corresponding states with the data. The function also sorts
     * all the areas in alphabetical order.
     */
    const getData = async () => {
      //Get all the data using various service functions
      const areasRes = await getAreaBranches<AreaSingle>(apiAddress);
      //const activeRes = await searchAllActiveTransactions({ area_id: id });
      const authorizedRes = await getAuthorizedUsers(id);
      const chargepointsRes = await getChargepoints(id);
      const areaMeterRes = await getAreaMeters();
      const userRes = await getUserRootAreas();
      const contractRes = await getAreaContract(id);
      //Check if all the requests came back as successful

      if (
        areasRes.success &&
        //activeRes.success &&
        authorizedRes.success &&
        chargepointsRes.success &&
        areaMeterRes.success &&
        userRes.success &&
        contractRes.success
      ) {
        //Store and sort all the areas and update the allAreas state

        const all = areasRes.data.flatMap((a) => a);

        const sortedAreas = all.sort((a: AreaSingle, b: AreaSingle) =>
          b.name.toLowerCase() < a.name.toLowerCase() ? 1 : -1
        );
        setAllAreas(sortedAreas);

        //Store all the chargepoints for this area and update the correct states
        setAllChargePoints(chargepointsRes.data);

        setContract(contractRes.data);

        //Store the area that is access at the moment and update the area state
        const areaData = sortedAreas.filter((area: AreaSingle) => area.id === id)[0];

        setArea(areaData);
        setPricingType(areaData.pricing_type!);
        setIsReporting(areaData.reporting_site!);
        setUserBiddingValue(areaData.bidding_area!);
        setSpotPriceMargin(areaData.margin!);
        //If the area is not the user_root area, set its parent to the state parent. This needs
        //to be checked since the allAreasData array does not include the parent of the user's own
        //root area (since they do not have access to it)
        setParent(
          sortedAreas.filter((area: AreaSingle) => {
            return area.id === areaData.parent;
          })[0]
        );

        //Update the remaining states
        setAuthorizedUsers(authorizedRes.data);

        setAllMeters(areaMeterRes.data);

        //TODO until we support all the primary manager functionality for single areas, I'll just map
        //the area id's to avoid large scale changes
        setUserRoot(userRes.data.map((a) => a.area_id));
        //Show the single area view
        setShowView(1);
      } else {
        //Errors when fetching the areas?
        if (!areasRes.success) {
          logger(areasRes.data);
        }

        //Errors when fetching the active transactions?
        /*         if (!activeRes.success) {
          logger(activeRes.data);
        } */

        //Errors when fetching the authorized users?
        if (!authorizedRes.success) {
          logger(authorizedRes.data);
        }

        //Errors when fetching the chargepoints?
        if (!chargepointsRes.success) {
          logger(chargepointsRes.data);
        }

        if (!areaMeterRes.success) {
          logger(areaMeterRes.data);
        }

        if (!userRes.success) {
          logger(userRes.data);
        }

        //Display an error message notifying that the user does not have access to the area
        setShowView(0);
      }
    };

    if (runUseEffectMode !== -1) getData();
    setRunUseEffectMode(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, setArea, setAllAreas, apiAddress, runUseEffectAgain]);

  /**
   * useEffect for fetching area meter values. Initially sets the showGraph state to -2 if the area doesn't
   * have an area meter, but otherwise it fetches area meter data for 24h back. Upon error, sets the showGraph
   * state to -3, otherwise to 0 or 1 depending on if there exists any data
   */
  useOnTabClicked(activeEventKey, ["areaMeterGraph"], () => {
    //Set showGraph to -2 if the area doesn't have a meter
    if (area) {
      if (!area.has_meter) {
        setShowGraph(-2);
        //Band-aid for bug showing recently viewed area_meter data even though
        //current area was mapped as having no meter
        setAreaMeterValues([]);
      } else {
        setShowGraph(-1);
        //Initialise the data to be sent to the backend
        const intervalDefault = 15;
        const d = new Date();
        const start = makeDate(
          d.getFullYear(),
          d.getMonth(),
          d.getDate() - 1, // -1 since we want the starting date to be 24h back
          d.getHours(),
          d.getMinutes(),
          d.getSeconds()
        );
        const stop = d;

        //Set the states containing the values for the html-date inputs and the interval input
        setStartDate(`${start.getFullYear()}-${pad(start.getMonth() + 1)}-${pad(start.getDate())}`);
        setStartTime(`${pad(start.getHours())}:${pad(start.getMinutes())}`);
        setStopDate(`${stop.getFullYear()}-${pad(stop.getMonth() + 1)}-${pad(stop.getDate())}`);
        setStopTime(`${pad(stop.getHours())}:${pad(stop.getMinutes())}`);
        setInterval(intervalDefault);

        //Data to be sent to the backend
        const body = {
          area_id: area!.id,
          start_date: start.toISOString(),
          stop_date: stop.toISOString(),
          period: intervalDefault,
        };

        // Asynchronous helper function for fetching the data from the backend
        const getData = async () => {
          //Fetch the data
          const res = await getAreaMeterValues(body);

          //Success?
          if (res.success) {
            // Parse the date fields for each data point (we need them in Javascript Date object form
            // and they're sent as ISO-Strings)
            const final = res.data.map((item: AreaMeterValue) => {
              const a = item;
              a.period_start = parseISOString(a.period_start);
              return a;
            });
            //Set the showGraph value to 0 if no data existed 24h back, otherwise to 1
            if (final.length === 0) {
              setShowGraph(0);
            } else {
              setShowGraph(1);
            }

            setAreaMeterValues(final);
          } else {
            //Errors occured, set the showGraph state to -3 and log the error
            setShowGraph(-3);
            logger(res.data);
          }
        };

        getData();
      }
    }
  });

  //Whenever any of the date or time input fields for the area meter value graph change, check if
  //the minimum allowed interval needs to be changed. This is to prevent the user from fetching waaaay
  //too much data
  useEffect(() => {
    const doAsync = async () => {
      const oneWeek = 604800000;
      //Create date objects and get their time difference
      //These have to be initialised as type any to allow for arithmetic operations
      const start: any = decodeAndMakeDate(startDate, startTime);
      const stop: any = decodeAndMakeDate(stopDate, stopTime);
      const difference = stop - start;

      /**
       * Update the interval (if the interval is lower than the threshold)
       * @param {*} threshold the minimum value the interval can be
       */
      const updateInterval = async (threshold: any) => {
        if (Number(interval) < threshold) setInterval(threshold);
      };

      if (difference >= 3 * oneWeek) {
        //If the difference is 3 weeks or more, set the minimum interval to 30
        await updateInterval(30);
        setIntervalMinValue(30);
      } else if (difference >= 2 * oneWeek) {
        //If the difference is between 2 and 3 weeks, set the minimum interval to 20
        await updateInterval(20);
        setIntervalMinValue(20);
      } else if (difference >= oneWeek) {
        //If the difference is between 1 and 2 weekds, set the minimum interval to 10
        await updateInterval(10);
        setIntervalMinValue(10);
      } else {
        //Otherwise, set the minimum interval to 2
        setIntervalMinValue(2);
      }
    };

    doAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, startTime, stopDate, stopTime]);

  /**
   * On each rerender, check if the user was redirected to this page with a state. If a redirect with a state
   * occured, check which state is active, and set the corresponding success alert on and remove the state.
   * The state is removed instantly after the alert is shown since the page should not render the success alert
   * when e.g. refreshing or accessing the page again.
   */
  useEffect(() => {
    //Check if a state exists
    if (history.location.state) {
      //Check which state exists.
      //window.history.replaceState was used instead of pushing the history stack to this page due to
      //the fact that pushing the history stack to this page causes a rerender to occur. This would
      //cause the success alert to be visible again when e.g. refreshing the page.
      //window.history.replaceState does not cause a rerender.

      if (history.location.state.cpDeleted) {
        //Set the successful alert for deleting a chargepoint .
        timer(setCPDeleted);
        window.history.replaceState({}, document.title);
      }
      if (history.location.state.cpMoved) {
        //Sets the successful alert when moving a chargepoint.
        timer(setCPMoved);
        window.history.replaceState({}, document.title);
      }
    }
  }, [history]);

  //Whenever the user changes the filter option for active transactions, display the correct transactions

  /**
   * Asynchronous helper function for refreshing all the authorized users to this area. This is used
   * whenever a user is authorized or deauthorized from this area. The function simply fetches the data
   * from the backend and updates the authorizedUsers state if successful. If it failed, log the error
   * message and display an error to the user notifying them that they do not have access to this area
   * (will be updated to show something else later)
   */
  const refreshAuthorizedUsers = async () => {
    //Fetch the data
    const res = await getAuthorizedUsers(id);

    //If successful, update the authorizedUsers state
    if (res.success) {
      setAuthorizedUsers(res.data);
    } else {
      logger(res.data);
      setShowView(0);
    }
  };

  /**
   * Asynchronous helper function for validating the area meter value graph search inputs.
   * @param {*} data the data to be validated
   * @param {*} setInvalidDates the state handler for showing an error message
   */
  const validateAreaMeterSearch = async (data: any, setInvalidDates: StateHandler<boolean>) => {
    if (data.start_date === "" || data.stop_date === "" || data.start_time === "" || data.stop_time === "") {
      //The user forgot to input the start and stop date and time, set the invalidDates state to true and return [false]
      setInvalidDates(true);
      return new StructuredResponse<Date[]>(false, []);
    }

    //Decode the data and create Javascript Date objects for the starting and stopping dates.
    const start = decodeAndMakeDate(data.start_date, data.start_time);
    const stop = decodeAndMakeDate(data.stop_date, data.stop_time);

    //If the start date is AFTER the stop date, set the invalidDates state to true and return [false]
    if (start > stop) {
      setInvalidDates(true);
      //Don't really like this but it is what it is
      return new StructuredResponse<Date[]>(false, []);
    }

    //Everything is good, return [true, start date, stop date]
    return new StructuredResponse(true, [start, stop]);
  };

  /**
   * Asynchronous helper function for fetching new area meter values from the backend. First, it validates
   * using the validateAreaMeterSearch function. If it fails, stop here. Otherwise, set the showGraph state to -1
   * ('Loading...'), and fetch the data. If it fails, set the showGraph state to -3. Otherwise, set it to 0 or 1
   * depending if the any data existed for that time span
   */
  const searchAreaMeterValues = async () => {
    //Data to be validated
    const validationData = {
      start_date: startDate,
      start_time: startTime,
      stop_date: stopDate,
      stop_time: stopTime,
    };
    const validationRes = await validateAreaMeterSearch(validationData, setInvalidDates);

    if (!validationRes.success) return; //If the validation fails, stop here

    setShowGraph(-1);

    //Should not be undefined but id rather not have the app crash or w/e
    if (area === undefined) {
      logger("Area is undefined");
      return;
    }
    //Data to be sent to the backend
    const body = {
      area_id: area!.id,
      start_date: validationRes.data[0].toISOString(),
      stop_date: validationRes.data[1].toISOString(),
      period: Number(interval),
    };

    const res = await getAreaMeterValues(body);

    //Success?
    if (res.success) {
      // Parse the date fields for each data point (we need them in Javascript Date object form
      // and they're sent as ISO-Strings)
      const final = res.data.map((item: AreaMeterValue) => {
        const a = item;
        a.period_start = parseISOString(a.period_start);
        return a;
      });
      //Set the showGraph value to 0 if no data existed 24h back, otherwise to 1
      if (final.length === 0) {
        setShowGraph(0);
      } else {
        setShowGraph(1);
      }

      setAreaMeterValues(final);
    } else {
      //Errors occured, set the showGraph state to -3 and log the error
      setShowGraph(-3);
      logger(res.data);
    }
  };

  const { t } = useTranslation("common", {
    i18n: i18N,
  });

  /**
   * Initially, the component shows a loading screen. If an error occured when fetching data (e.g. the user
   * does not have access to the area), an appropriate error message is displayed. Otherwise,
   * the component returns either the SingleArea component, EditArea component, AddArea component, or the
   * AddChargepoint component. This depends on the states defined at the top of this component.
   */
  return (
    <StyledSingleArea data-cy="scrollable-single-area" className="top-level-component">
      {showView === -1 ? ( //showView is -1 when the component is initialized, display "Loading...".
        <>
          <h2 className="align-self-center">{t("global.view.loading")}</h2>
        </>
      ) : showView === 0 ? ( //if showView is 0, an error occured when retrieving the data from the backend.
        <h2 className="align-self-center">{t("global.view.noAccess")}</h2>
      ) : (
        <Container id="component-margin">
          {area ? (
            !editMode && !addCpMode && !addDirectArea ? (
              <SingleArea
                activeEventKey={activeEventKey}
                setActiveEventKey={setActiveEventKey}
                user={user}
                area={area}
                toggleEdit={toggleEdit}
                toggleAddDirectArea={toggleAddDirectArea}
                parent={parent!}
                chargePoints={allChargePoints}
                toggleAddCp={toggleAddCp}
                setShowLoading={setShowLoading}
                setShowCheckmark={setShowCheckmark}
                showCheckmark={showCheckmark}
                showLoading={showLoading}
                history={history}
                authorizedUsers={authorizedUsers}
                CPMoved={CPMoved}
                refreshAuthorizedUsers={refreshAuthorizedUsers}
                CPDeleted={CPDeleted}
                areaAdded={areaAdded}
                CPAdded={CPAdded}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                areaMeterValues={areaMeterValues}
                showGraph={showGraph}
                interval={interval}
                intervalMinValue={intervalMinValue}
                startDate={startDate}
                startTime={startTime}
                stopDate={stopDate}
                stopTime={stopTime}
                setInterval={setInterval}
                setStartDate={setStartDate}
                setStartTime={setStartTime}
                setStopDate={setStopDate}
                setStopTime={setStopTime}
                searchAreaMeterValues={searchAreaMeterValues}
                invalidDates={invalidDates}
                contract={contract!}
                isReporting={isReporting}
                setIsReporting={setIsReporting}
                allAreas={allAreas}
                pricingType={pricingType}
                setPricingType={setPricingType}
                allChargePoints={allChargePoints}
              />
            ) : editMode ? (
              <EditArea
                area={area}
                allMeters={allMeters}
                toggleEdit={toggleEdit}
                allAreas={allAreas}
                history={history}
                userRoot={userRoot}
                authorizedUsers={authorizedUsers}
                refreshAuthorizedUsers={refreshAuthorizedUsers}
                chargepoints={allChargePoints}
                runUseEffect={runUseEffect}
                contract={contract!}
                user={user}
                setIsReporting={setIsReporting}
                isReporting={isReporting}
                pricingType={pricingType}
                setPricingType={setPricingType}
                spotPriceMargin={spotPriceMargin}
                setSpotPriceMargin={setSpotPriceMargin}
                userBiddingValue={userBiddingValue}
                setUserBiddingValue={setUserBiddingValue}
              />
            ) : addDirectArea ? (
              <AddArea
                toggleAdd={toggleAddDirectArea}
                areas={allAreas}
                startingParent={area}
                runUseEffect={runUseEffect}
                userRoot={userRoot}
                history={history}
                contract={contract!}
                isReporting={isReporting}
                setIsReporting={setIsReporting}
                user={user}
                handleLimit={handleLimit}
                pricingType={pricingType}
                setPricingType={setPricingType}
              />
            ) : (
              <AddChargepoint toggleAddCp={toggleAddCp} area={area} runUseEffect={runUseEffect} user={user} />
            )
          ) : (
            <></>
          )}
        </Container>
      )}
    </StyledSingleArea>
  );
};

export default SingleAreaView;
