import { useState, useEffect } from "react";
import { Alert, InputGroup, Form, Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import StructuredResponse from "../../../model/Classes/StructuredResponse";
import User from "../../../model/Classes/User";
import { Nullable, StateHandler } from "../../../model/Utilities/Types";
import { logout } from "../../../services/authService";
import { logger } from "../../../utils/logger";
import { timer } from "../../../utils/timer";
import { toggleAlertsOff } from "../../../utils/toggleAlertsOff";

/**
 * Special component shown when the user has successfully changed their password. Shown for 3 seconds
 * before the user is logged out and redirected to the login page.
 * @returns successful password change page
 */
export const SuccessfullPasswordChange = () => {
  const { t } = useTranslation("common", { i18n: i18n });
  return (
    <Row>
      <Alert key="successChange" variant="success">
        {t("global.alert.success.nameChange")}
      </Alert>
    </Row>
  );
};

type UserSettingsPasswordProps = {
  oldPassword: string;
  newPassword: string;
  newConfirmPassword: string;
  setOldPassword: StateHandler<string>;
  setNewPassword: StateHandler<string>;
  setNewConfirmPassword: StateHandler<string>;
  setUser: StateHandler<Nullable<User>>;
  setSuccessfullPasswordChange: StateHandler<boolean>;
  handleSubmitUserSettingsPassword: () => Promise<StructuredResponse<any>>;
  history: any;
};

/**
 *
 * @param param0
 * @returns
 */
const UserSettingsPassword = ({
  oldPassword,
  newPassword,
  newConfirmPassword,
  setOldPassword,
  setNewPassword,
  setNewConfirmPassword,
  setUser,
  setSuccessfullPasswordChange,
  handleSubmitUserSettingsPassword,
  history,
}: UserSettingsPasswordProps) => {
  const [showError, setShowError] = useState(false); //state for showing an error alert
  const [showOldWrongAlert, setShowOldWrongAlert] = useState(false); //state for showing an alert if the old password is wrong
  const [showShortAlert, setShowShortAlert] = useState(false); //state for showing an alert if the new password is too short
  const [showConfirmWrongAlert, setShowConfirmWrongAlert] = useState(false); //state for showing an alert if the new password and confirm password fields don't match

  const { t } = useTranslation("common", { i18n: i18n });
  //useEffect hook that runs upon initialization. Simply toggles all the alerts off
  useEffect(() => {
    //Call the utility function toggleAlertsOff to toggle all the alerts off
    toggleAlertsOff([setShowError, setShowOldWrongAlert, setShowShortAlert, setShowConfirmWrongAlert]);
  }, []);

  /**
   * Helper function to validate the input fields. The function checks for multiple criteria, and if a criteria fails
   * it sets the success variable to false and toggles the respective alert on.
   * This is done instead of instantly returning false, because then all the criteria are checked instead of the
   * function stopping once the first violation occured.
   *
   * NOTE! The old password cannot be validated on the front end, and must thus be validated on the backend. Hence,
   * no need to validate the old password here.
   * @returns true if the validation passes, false otherwise
   */
  const validate = () => {
    //Return value
    let success = true;

    if (newPassword.length < 8) {
      success = false;
      setShowShortAlert(true);
    } //Check if the new password is too short
    if (newPassword !== newConfirmPassword) {
      success = false;
      setShowConfirmWrongAlert(true);
    } //Check if the new password and confirm password fields don't match

    return success;
  };

  /**
   * Asynchronous helper function for calling the validate() function, and based on the result, call the helper function
   * handleSubmitUserSettingsPassword() in the UserSettings component to send the data changes to the backend or show
   * an error alert.
   */
  const checkUpdate = async () => {
    //Call the utility function toggleAlertsOff to toggle all the alerts off
    toggleAlertsOff([setShowError, setShowOldWrongAlert, setShowShortAlert, setShowConfirmWrongAlert]);

    //Call the validate() helper function and check the return value. If true, continue, otherwise don't
    //do anything
    if (validate()) {
      //Validation passed, call the handleSubmitUserSettingsPassword() helper function
      const res = await handleSubmitUserSettingsPassword();
      //If the submission was successful, call the function showSuccessAlert(). Otherwise,
      //call the showErrorAlert() function
      res.success ? showSuccessAlert() : showErrorAlert();
    }
  };

  /**
   * Helper function for handling the functionality after the user successfully changes their password.
   * Initially, it sets the successfullPasswordChange state to true, which will trigger the UserSettings
   * component to display the SuccessfullPasswordChange component. After 3 seconds, try to log out the
   * user and push the user to the login page. If some error was caught, log the error. Finally, set
   * the successfullPasswordChange state to false.
   */
  const showSuccessAlert = () => {
    //Trigger the UserSettings component to display the SuccessfullPasswordChange component.
    setSuccessfullPasswordChange(true);

    //Set a timeout for 3 seconds, after which the program executes the callback function.
    setTimeout(async () => {
      try {
        await logout(); //Call the logout() service function

        //Update the user state
        setUser(null);

        //Push the user to the login page
        history.push("/login");
      } catch (e) {
        logger(e);
      }

      //Finally, set the successfullPasswordChange state to false
      setSuccessfullPasswordChange(false);
    }, 3000);
  };

  /**
   * Helper function for triggering the error alert
   */
  const showErrorAlert = () => {
    timer(setShowError);
  };
  return (
    <>
      {/*Row for alerts and the form fields*/}
      <Row>
        {showError && ( //Show error alert if needed
          <Alert key="error" variant="danger">
            {t("global.view.errorTry")}
          </Alert>
        )}
        {/*Simple text input field for the old password field*/}
        <InputGroup className="mb-3">
          <InputGroup.Text id="name">{t("components.userSettings.tabs.password.old")}</InputGroup.Text>
          <Form.Control
            type="password"
            value={oldPassword}
            aria-label="Old password"
            aria-describedby="Old password"
            onChange={(event) => setOldPassword(event.target.value)}
          />
        </InputGroup>
        {showOldWrongAlert && ( //Show alert notifying the user if the old password input was invalid
          <Alert key="wrongOld" variant="danger">
            {t("global.alert.failure.oldPw")}
          </Alert>
        )}

        {/*Simple text input field for the new password field*/}
        <InputGroup>
          <InputGroup.Text id="name">{t("components.userSettings.tabs.password.new")}</InputGroup.Text>
          <Form.Control
            type="password"
            value={newPassword}
            aria-label="New password"
            aria-describedby="New password"
            onChange={(event) => setNewPassword(event.target.value)}
          />
        </InputGroup>
        <Form.Text className="text-muted">{t("components.userSettings.tabs.password.note")}</Form.Text>
        {showShortAlert && ( //Show alert notifying the user if the new password is too short
          <Alert key="tooShort" variant="danger" className="mt-3">
            {t("global.alert.failure.pwShort")}
          </Alert>
        )}

        {/*Simple text input field for the confirm password field */}
        <InputGroup className="mb-3 mt-3">
          <InputGroup.Text id="name">{t("components.userSettings.tabs.password.confirm")}</InputGroup.Text>
          <Form.Control
            type="password"
            value={newConfirmPassword}
            aria-label="Confirm password"
            aria-describedby="Confirm password"
            onChange={(event) => setNewConfirmPassword(event.target.value)}
          />
        </InputGroup>
        {showConfirmWrongAlert && ( //Show alert notifying the user if the new password and confirm password don't match
          <Alert key="notMatching" variant="danger" className="mt-3">
            {t("global.alert.failure.noPwMatch")}
          </Alert>
        )}
      </Row>
      {/*Row for the update password button*/}
      <Row>
        {/*The save button needs to be wrapped around a Col component, since otherwise it will
                   stretch across the whole screen*/}
        <Col xs={3} sm={3}>
          {showError ? (
            <Button variant="primary" onClick={checkUpdate} disabled>
              {t("components.userSettings.tabs.password.update")}
            </Button>
          ) : (
            <Button variant="primary" onClick={checkUpdate}>
              {t("components.userSettings.tabs.password.update")}
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

export default UserSettingsPassword;
