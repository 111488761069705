import {
  tokenRenewingGet,
  tokenRenewingPost,
  tokenRenewingPatch,
  tokenRenewingDelete,
} from "./lib/tokenRenewingRequest";

import { TransactionCp } from "../model/Classes/Chargepoint";
import { Tag, PatchableTag, PostableTag, PostableDefaultTag, TagScanEvent } from "../model/Classes/Tag";
import InvoicingInfo from "../model/Classes/InvoicingInfo";

// Tags

export const postTag = async (data: PostableTag) => await tokenRenewingPost<Tag>("/api/v2/tags", data);

export const postDefaultTag = async (data: PostableDefaultTag) => await tokenRenewingPost("/api/v2/tags/default", data);

export const editTag = async (id: number, data: PatchableTag) =>
  await tokenRenewingPatch<Tag>(`/api/v2/tags/${id}`, data);

export const deleteTag = async (id: number) => await tokenRenewingDelete<any>(`/api/v2/tags/${id}`);

export const enableTag = async (id: number) =>
  await tokenRenewingPatch<Tag | null>(`/api/v2/tags/${id}`, { disabled: 0 } as PatchableTag);

export const disableTag = async (id: number) =>
  await tokenRenewingPatch<Tag | null>(`/api/v2/tags/${id}`, { disabled: 1 } as PatchableTag);

export const getDefaultTag = async () => await tokenRenewingGet<Tag>("/api/v2/tags/default");

export const getUserTags = async () => await tokenRenewingGet<Tag[]>("/api/v2/tags");

export const generateTagIdFromUI = async () => await tokenRenewingGet<string>("/api/user/tags/generate");

export const getRecentTagScanEvent = async (chargePointId: string) =>
  await tokenRenewingGet<TagScanEvent | null>(`/api/v2/tags/scan/${chargePointId}`);

export const getAllChargepointsForTagGeneration = async () =>
  await tokenRenewingGet<TransactionCp[]>("/api/user/settings/chargepoints");

// Invoices and payments

export const getEmailInvoiceInfo = async () => await tokenRenewingGet<any>("/api/user/emailinvoicing");

export const getPaymentsOutstanding = async () =>
  await tokenRenewingGet<{ total_price: number }>("/api/user/payments/outstanding");

export const getPaymentMethodStatus = async () => await tokenRenewingGet<any>("/api/user/paymentMethodStatus");

export const getInvoicesOutstanding = async () => await tokenRenewingGet<any>("/api/user/invoices/outstanding");

export const sendEmailInvoiceInfo = async (data: InvoicingInfo) =>
  await tokenRenewingPost<any>("/api/user/emailInvoicing", data); // Return type unspecififed, note camelCase URL

// Settings and password

export const sendUserSettingsBasic = async (data: any) =>
  await tokenRenewingPost<any>("/api/user/settings/basic", data);

export const sendUserSettingsLanguage = async (data: any) =>
  await tokenRenewingPost<any>("/api/user/settings/language", data);

export const sendUserSettingsPassword = async (data: { old_password: string; new_password: string }) =>
  await tokenRenewingPost<any>("/api/user/settings/password", data);
