import { useEffect, useState } from "react";

import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Legend } from "recharts";

import i18N from "../../../../i18n";
import { useTranslation } from "react-i18next";
import { logger } from "../../../../utils/logger";

import { useWindowDimensions } from "../../../../model/Utilities/ReactHelpers";

import { getCpSpotprice } from "../../../../services/chargepointService";

import { Nullable } from "../../../../model/Utilities/Types";

const SMALL_SCREEN_BREAKPOINT = 500;

export const SpotPriceWidget = ({
  chargepointId,
  margin,
}: {
  chargepointId?: string;
  margin?: Nullable<string | number>;
}) => {
  const { t } = useTranslation("common", {
    i18n: i18N,
  });

  const [spotPrices, setSpotPrices] = useState<Array<any>>([]);
  //const [vatRate, setVatRate] = useState(0);

  const { windowWidth } = useWindowDimensions();

  useEffect(() => {
    const getData = async () => {
      try {
        if (!chargepointId) {
          throw Error("No chargepoint selected");
        }
        const spotPriceRes = await getCpSpotprice(chargepointId);

        if (spotPriceRes.success && spotPriceRes.data.length) {
          let formattedSpotPrices = spotPriceRes.data.flatMap((entry) => {
            //return Array(4).fill({ // For simulating quarterly prices
            let marginInCents = Number(margin ?? 0) * 100;
            let spotPriceAsFloat = parseFloat(String(entry.spotPrice)); // data.spotPrice _might_ be a string, so parse it
            // Since rendering a negative spot price doesn't work well, we instead
            // deduct a negative spot price from the margin.
            return {
              ...entry,
              spotPrice: Math.max(0, spotPriceAsFloat), // Don't show negative values here...
              margin: spotPriceAsFloat < 0 ? marginInCents + spotPriceAsFloat : marginInCents, // ...but add a negative price to margin
            };
            //});
          });
          setSpotPrices(formattedSpotPrices);
        }
      } catch (e) {
        logger(e);
      }
    };

    getData();
  }, [chargepointId]);

  const tickFormatterY = (tickValue: any): string => {
    return Number(tickValue).toFixed(1) + "¢";
  };

  const tickFormatterX = (tickValue: any): string => {
    let dateValue = new Date(tickValue);
    if (dateValue.getMinutes() === 0) {
      return String(dateValue.getHours()).padStart(2, "0");
    } else {
      return "";
    }
  };

  const sliceSize = windowWidth < SMALL_SCREEN_BREAKPOINT ? 9 * 4 : 12 * 4;

  return (
    <div>
      <ResponsiveContainer width="100%" height={240}>
        <BarChart data={spotPrices.slice(0, sliceSize)}>
          <CartesianGrid vertical={false} strokeWidth={0.5} />
          <XAxis tickFormatter={tickFormatterX} dataKey="local_time" tickLine={false} />
          <YAxis tickFormatter={tickFormatterY} type="number" domain={[(dataMin) => Math.min(dataMin, 0), "auto"]} />
          <Bar dataKey="margin" name={t("components.startTransaction.view.Margin")} stackId="a" fill="#005640aa" />
          <Bar
            dataKey="spotPrice"
            name={t("components.startTransaction.view.SpotPrice")}
            stackId="a"
            fill="#00A551aa"
          />
          <Legend verticalAlign="top" height={40} />
        </BarChart>
      </ResponsiveContainer>

      <p className="mt-3 text-center">
        {t("components.startTransaction.nordpoolTip")}
        &nbsp;
        <a
          href="https://data.nordpoolgroup.com/auction/day-ahead/prices?deliveryAreas=FI,SE1,SE2,SE3,SE4&currency=EUR&aggregation=Hourly"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("components.startTransaction.nordpool")}
        </a>
      </p>
    </div>
  );
};
