import { Contract } from "../model/Classes/Contract";
import { AreaBase } from "../model/Classes/Area";
import { tokenRenewingGet, tokenRenewingPost } from "./lib/tokenRenewingRequest";

// Contract Management
export const getContract = async (contract_id: number, url?: string) =>
  await tokenRenewingGet<[Contract, AreaBase[]]>(`${url ? url : "/api/reporting/contract"}/single/${contract_id}`);

export const addContract = async (contract: Contract) =>
  await tokenRenewingPost<any>("/api/admin/contract/add", { contract });

export const getAreaContract = async (area_id: number) =>
  await tokenRenewingGet<Contract>(`/api/reporting/contract/area/${area_id}`);

export const getContracts = async () => await tokenRenewingGet<Contract[]>("/api/admin/contract/all");

export const getAvailableContracts = async () =>
  await tokenRenewingGet<Contract[]>("/api/reporting/contract/available");

export const updateContract = async (apiAddress: string, contract: Contract) =>
  await tokenRenewingPost<any>(`${apiAddress}/update/${contract.contract_number}`, contract);

// Manager Management
export const getPrimaryManager = async (contract_number: number) =>
  await tokenRenewingGet<{ primary_manager: string }>(`/api/admin/contract/${contract_number}/primary`);

export const updatePrimaryManager = async (contract_number: number, email: string) =>
  await tokenRenewingPost<{ status: number; message: string }>(`/api/admin/contract/primary/${contract_number}`, {
    email,
  });

export const setAreaManager = async (data: { email: string; area_id: number }) =>
  await tokenRenewingPost<{ status: number; message: string }>("/api/admin/makeAreaManager", data);
