import { tokenRenewingGet, tokenRenewingPost } from "./lib/tokenRenewingRequest";

interface TokenizationIdPayload {
  checkout_account: string;
  checkout_algorithm: string;
  checkout_method: string;
  checkout_status: string;
  signature: string;
  checkout_tokenization_id: string;
}

/* All methods here depend on the Paytrail implementation, although most work only
 * with internal services that query our own database. Consider moving these to
 * the user service or restructuring for abstraction of payment providers.
 * */

// These use the Paytrail API in the back end
export const addCard = async () => await tokenRenewingGet<any>("/api/user/cards/cardForm");

export const chargeUserInvoices = async () => await tokenRenewingPost<any>("/api/user/invoices/charge", undefined);

export const sendInvoicesToPaytrail = async (data: { user_invoice_id: any }) =>
  await tokenRenewingPost<any>("/api/admin/invoicing/chargeTokens", data);

export const sendTokenizationId = async (data: TokenizationIdPayload) =>
  await tokenRenewingPost<any>("/api/user/cards/addTokenizationId", data);

// These only update our own database
export const cardsExpiring = async () => await tokenRenewingGet<any>("/api/user/cards/expiring");

export const defaultCardExpired = async () => await tokenRenewingGet<any>("/api/user/cards/expired");

export const deleteCard = async (data: { card_id: number | string }) =>
  await tokenRenewingPost<any>("/api/user/cards/delete", data);

export const getCards = async () => await tokenRenewingGet<any>("/api/user/cards");

export const setDefaultCard = async (data: { card_id: number | string }) =>
  await tokenRenewingPost<any>("/api/user/cards/setDefault", data);
