import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./fontStyles.css";
import "react-credit-cards/es/styles-compiled.css";
import i18n from "./i18n";

/**
 * Main file where the ReactDOM recides. The App component is wrapped around a Router component
 * from react-router-dom to allow the the switch and route components in App.js to work correctly.
 * The application is mainly built using the react-bootstrap components, with custom styling built upon
 * the bootstrap.css file.
 * The use of react-bootstrap containers, rows, and columns makes sure that the entire application
 * is media friendly (i.e. compatible for all screen sizes)
 */
//Apparently calling changeLanguage without arguments defaults to browser language detection
//Doesnt seem to work reliably
i18n.changeLanguage();
ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);

if ("serviceWorker" in navigator) serviceWorkerRegistration.register();
