import { useEffect, useState } from "react";
import { Col, Alert } from "react-bootstrap";
import { StyledMaintenanceNotification } from "./MaintenanceNotification.styled";
import i18n from "../../i18n";

export const MaintenanceNotification = () => {
  const [notification, setNotification] = useState(() => {
    return {
      active: false,
      fi: "",
      sv: "",
      en: "",
    };
  });

  useEffect(() => {
    fetch("maintenance_notif.json")
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch maintenance notification");
        }
        return res.json();
      })
      .then((response) => {
        setNotification(response);
      })
      .catch((error) => {
        console.error("Could not fetch maintenance notification.");
        setNotification({
          active: false,
          fi: "",
          sv: "",
          en: "",
        });
      });
  }, []);

  return (
    <>
      {notification.active ? (
        <StyledMaintenanceNotification>
          <Col style={{ textAlign: "center", marginLeft: "10vw", marginRight: "10vw" }}>
            <Alert variant="warning" className="w-60 mx-auto" style={{ maxWidth: "600px" }}>
              {notification[i18n.language as keyof typeof notification]}
            </Alert>
          </Col>
        </StyledMaintenanceNotification>
      ) : (
        <></>
      )}
    </>
  );
};
