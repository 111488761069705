import { useState, useRef } from "react";
import i18N from "../../../i18n";
import { useTranslation } from "react-i18next";
import logo from "../../../resources/WatteryLogoGreen.svg";
import { Container, Row, Col, Button, Form, Image, Alert } from "react-bootstrap";
import { useParams } from "react-router";
import { resetPassword } from "../../../services/authService";
import { timer } from "../../../utils/timer";
import { toggleAlertsOff } from "../../../utils/toggleAlertsOff";
import ReCAPTCHA from "react-google-recaptcha";
import { StyledForgotPassword } from "./ResetPassword.styled";
import { logger } from "../../../utils/logger";

/**
 * Reset password form that contains the following:
 * - Wattery logo
 * - Possible alerts (reset failed)
 * - Password inputs
 * - Submit button
 * - Possible alerts (passwords don't match, or the password is too short or too long)
 * @param {*} setNewPassword state handler for the newPassword state
 * @param {*} setConfirmPassword state handler for the confirmPassword state
 * @param {*} failedReset state for showing an alert notifying the user that the reset failed
 * @param {*} tooShort state for showing an alert notifying the user that the password is too short
 * @param {*} tooLong state for showing an alert notifying the user that the password is too long
 * @param {*} notSame state for showing an alert notifying the user that the passwords don't match
 * @param {*} handleSubmit helper function for handling the submission of the form
 * @param {*} recaptchaRef ref for the recaptcha
 * @param {*} recaptchaNotClicked state for showing an alert notifying the user to press the reCaptcha
 * @returns reset password form
 */
const ResetPasswordForm = ({
  setNewPassword,
  setConfirmPassword,
  tooShort,
  notSame,
  tooLong,
  failedReset,
  handleSubmit,
  recaptchaRef,
  recaptchaNotClicked,
}) => {
  const { t, i18n } = useTranslation("common", {
    i18n: i18N,
  });
  return (
    <Container id="component-margin">
      <Row>
        <Image className="logo" src={logo} alt="Wattery logo" />
      </Row>
      <Row>
        {failedReset && (
          <Alert key="failedReset" variant="danger">
            {t("components.resetPassword.alerts.failed")}
          </Alert>
        )}
      </Row>
      <Form>
        <Form.Group as={Row} className="mb-3" style={{ marginTop: 20, textAlign: "center" }}>
          <h2>{t("components.resetPassword.title")}</h2>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" onChange={(event) => setNewPassword(event.target.value)}>
          <Col sm={3}>
            <Form.Label>{t("components.resetPassword.form.newPassword")}</Form.Label>
          </Col>
          <Col>
            <Form.Control
              type="password"
              placeholder={t("components.resetPassword.placeholders.newPassword")}
              id="new-password"
            />
            <Form.Text className="text-muted">{t("components.resetPassword.form.passwordLengthHint")}</Form.Text>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" onChange={(event) => setConfirmPassword(event.target.value)}>
          <Col sm={3}>
            <Form.Label>{t("components.resetPassword.form.confirmPassword")}</Form.Label>
          </Col>
          <Col>
            <Form.Control
              type="password"
              placeholder={t("components.resetPassword.placeholders.confirmPassword")}
              id="confirm-new-password"
            />
          </Col>
        </Form.Group>

        <ReCAPTCHA className="mb-3" ref={recaptchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!} />

        <Form.Group as={Row} className="mb-3">
          <Col>
            <Button type="submit" onClick={handleSubmit} id="submit-reset-password">
              {t("components.resetPassword.form.submitButton")}
            </Button>
          </Col>
        </Form.Group>
      </Form>
      <Row>
        {tooLong && (
          <Alert key="tooLongAlert" variant="danger">
            {t("components.resetPassword.alerts.tooLong")}
          </Alert>
        )}
        {tooShort && (
          <Alert key="tooShortAlert" variant="danger">
            {t("components.resetPassword.alerts.tooShort")}
          </Alert>
        )}
        {notSame && (
          <Alert key="notSameAlert" variant="danger">
            {t("components.resetPassword.alerts.noMatch")}
          </Alert>
        )}
        {recaptchaNotClicked && (
          <Alert key="recaptchaNotClicked" variant="danger">
            {t("components.resetPassword.alerts.recaptcha")}
          </Alert>
        )}
      </Row>
    </Container>
  );
};

/**
 * Component responsible for the functionality for resetting a password (after the user has click the link in their email).
 * The component handles the submission of the form to the backend. If resetting a password is successful,
 * the user is redirected to the login page where an alert is shown to notify the user that resetting the
 * succeeded.
 * @param {*} history history object
 * @param {*} setResetPasswordSuccessAlert state handler for an alert notifying the user that resetting the password was successful
 * @returns reset password view
 */
const ResetPassword = ({
  history,
  setResetPasswordSuccessAlert,
  //setPasswordResetSubmitFailed,
}) => {
  const [newPassword, setNewPassword] = useState(""); //state containing the new password input field
  const [confirmPassword, setConfirmPassword] = useState(""); //state containing the confirm password input field
  const [tooShort, setTooShort] = useState(false); //state for showing an alert notifying the user that the password is too short
  const [tooLong, setTooLong] = useState(false); //state for showing an alert notifying the user that the password is too long
  const [notSame, setNotSame] = useState(false); //state for showing an alert notifying the user that the passwords don't match
  const [failedReset, setFailedReset] = useState(false); //state for showing an alert notifying the user that resetting the password failed
  const [recaptchaNotClicked, setRecaptchaNotClicked] = useState(false); //state for showing an alert notifying the user to pass the reCaptcha

  const token = useParams<{ token: string }>().token; //Token from the url parameters
  const recaptchaRef = useRef<any>();

  /**
   * Helper function for validating the input fields. The function checks for multiple criteria, and if a criteria fails
   * it sets the pass variable to false and toggles the respective alert on.
   * This is done instead of instantly returning false, because then all the criteria are checked instead of the
   * function stopping once the first violation occured.
   *
   * @param {*} captchaToken value for the captcha token
   * @returns true if validation passed, false otherwise
   */
  const validate = (captchaToken) => {
    //Return value
    let success = true;

    if (newPassword.length > 71) {
      success = false;
      setTooLong(true);
    } //Password is too long
    if (newPassword.length < 8) {
      success = false;
      setTooShort(true);
    } //Password is too short
    if (newPassword !== confirmPassword) {
      success = false;
      setNotSame(true);
    } //Passwords do not match
    if (captchaToken === "") {
      success = false;
      setRecaptchaNotClicked(true);
    }

    return success;
  };

  /**
   * Helper function for submitting the form. The function toggles all alerts off, and calls the
   * validate() function. If the validation fails, the function stops there. Otherwise, it tries
   * to send the data to the backend, and upon success, it pushes the user to the login page where
   * an alert is show notifying the user that changing the password succeeded. If it failed, show
   * and alert notifying the user of the failure.
   * @param {*} event event
   * @returns
   */
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    //Toggle all the alerts of
    toggleAlertsOff([setTooLong, setTooShort, setNotSame, setRecaptchaNotClicked]);

    const captchaToken = await recaptchaRef.current.getValue();

    if (!validate(captchaToken)) return; //If the validation failed, exit the function

    //Data to be sent to the backend
    const data = {
      email_token: token,
      new_password: newPassword,
      verification: confirmPassword,
      reCaptchaToken: captchaToken,
    };

    try {
      //Call the resetPassword() service function to send the data to the backend.
      await resetPassword(data);

      //Resetting the password succeeded, push the user to the login page and toggle on the alert notifying
      //the user that the reset was successful.
      history.push("/login");
      timer(setResetPasswordSuccessAlert);
    } catch (e) {
      logger(e);
      //An error occured, toggle an alert for 6 seconds to notify the user of the failure
      timer(setFailedReset, 6000);
    }
  };

  return (
    <StyledForgotPassword className="top-level-component">
      <ResetPasswordForm
        setNewPassword={setNewPassword}
        setConfirmPassword={setConfirmPassword}
        tooShort={tooShort}
        notSame={notSame}
        tooLong={tooLong}
        failedReset={failedReset}
        handleSubmit={handleSubmit}
        recaptchaRef={recaptchaRef}
        recaptchaNotClicked={recaptchaNotClicked}
      />
    </StyledForgotPassword>
  );
};

export default ResetPassword;
