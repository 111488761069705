import { tokenRenewingGet, tokenRenewingPost } from "./lib/tokenRenewingRequest";
import User from "../model/Classes/User";

export const renewTokens = async () => await tokenRenewingGet<any>("/api/auth/renewTokens");

export const confirmEmail = async (data: any) => await tokenRenewingPost<any>("/api/auth/confirmEmail", data);

export const validateInviteToken = async (invite_token: string) =>
  await tokenRenewingGet<any>(`/api/auth/register/invite/${invite_token}`);

export const login = async (data: { email: string; password: string }) =>
  await tokenRenewingPost<User>("/api/auth/login", data);

export const logout = async () => await tokenRenewingPost<any>("/api/auth/logout");

export const register = async (data: any) => await tokenRenewingPost<any>("/api/auth/register", data);

export const resetPassword = async (data: any) => await tokenRenewingPost<any>("/api/auth/resetPassword", data);

export const requestPasswordReset = async (data: any) =>
  await tokenRenewingPost<any>("/api/auth/requestPasswordReset", data);
