export default class StructuredResponse<T> {
  private readonly _success: boolean;
  private readonly _data: T;
  private readonly _status: number | undefined;

  //StructuredResponse is an more friendly version of the previous service function return tuple [boolean, data]
  //but is also used as a substitute for other tuple responses present in the system
  constructor(success: boolean, data: T, status?: number) {
    this._success = success;
    this._data = data;
    this._status = status;
  }

  public get success(): boolean {
    return this._success;
  }

  public get data(): T {
    return this._data;
  }

  public get status(): number | undefined {
    return this._status;
  }
}
