import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import User from "../../../model/Classes/User";
import { StateHandler } from "../../../model/Utilities/Types";
import { ReportingInvoices } from "./ReportingComponents";
import { ReportingCompletedTransactionsProps, ReportingCompletedTransactions } from "./ReportingTransactions";
import { ReportingInvoice } from "../../../model/Classes/Invoice";

declare interface ReportingLayoutProps extends ReportingCompletedTransactionsProps {
  invoices: ReportingInvoice[];
  user: User;
  setSelectedInvoices: StateHandler<any[]>;
  sendToPayTrail: () => Promise<void>;
  sendToPaytrailHistory: any[];
  companyInvoices: number[];
  hasCompanyInvoices: boolean;
}
/**
 * Component responsible for displaying the reporting page. The component contains a tab navigation between
 * the completed transactions (rendered by the ReportingCompletedTransactions component) and active transactions
 * (rendered by the ActiveTransactions component). Additionally, the component contains an array headers that is
 * needed for exporting the report to a .csv file (see ReportingCompletedTransactions for more details).
 *
 * The reason why this component has so many props is due to the fact that the two tabs are rendered with the
 * help of the ReportingCompletedTransactions component and ActiveTransactions component respectively. The
 * components require a lot of information + helper functions + states + state handlers, hence all the props.
 * @param {*} areas state conatining all the areas the user has access to
 * @param {*} areaField state for the input area containing the area name, area id, and user root flag
 * @param {*} allTransactions state containing all the transactions for the report
 * @param {*} startDate state containing the start date in html date input format (YYYY:MM:DD)
 * @param {*} startTime state containing the start time in html date input format (HH:MM)
 * @param {*} stopDate state containing the stop date in html date input format (YYYY:MM:DD)
 * @param {*} stopTime state containing the stop time in html date input format (HH:MM)
 * @param {*} setAreaField state handler for the areaField state
 * @param {*} setStartDate state handler for the startDate state
 * @param {*} setStartTime state handler for the startTime state
 * @param {*} setStopDate state handler for the stopDate state
 * @param {*} setStopTime state handler for the stopTime state
 * @param {*} searchBasic helper function for conducting the "Basic" mode search
 * @param {*} searchThisMonth helper function for conducting the "This month" mode search
 * @param {*} searchPreviousMonth helper function for conducting the "Previous month" mode search
 * @param {*} searchPreviousQuarter helper function for conducting the "Previous quarter" mode search
 * @param {*} searchPreviousYear helper function for conducting the "Previous year" mode search
 * @param {*} searching state for showing the loading animation
 * @param {*} searchComplete state for showing the checkmark when the loading is complete
 * @param {*} invalidDates state for showing an error alert notifying the user that the input start and stop dates are invalid
 * @param {*} rangeAreaName state containing the string to show for which area the reports are for
 * @param {*} rangeText state containing the string to show for which period the reports are for
 * @returns reporting view
 */
export const ReportingLayout = ({
  areas,
  areaField,
  allTransactions,
  displayTransactions,
  selectedTransactions,
  handleSetSelectedTransactions,
  startDate,
  stopDate,
  setAreaField,
  setStartDate,
  setStopDate,
  searchBasic,
  search,
  searching,
  searchComplete,
  invalidDates,
  rangeAreaName,
  rangeText,
  invoices,
  createInvoices,
  noRowChosen,
  createInvoiceFail,
  createInvoiceSuccess,
  user,
  notReportingSite,
  setSelectedInvoiceMethod,
  setInvoicingFilter,
  selectedInvoiceMethod,
  invoicingFilter,
  setSelectedInvoices,
  sendToPayTrail,
  sendToPaytrailHistory,
  companyInvoices,
  hasCompanyInvoices,
  currArea,
  setCurrArea,
  checkCards,
  cardsSuccess,
  cardsFail,
}: ReportingLayoutProps) => {
  const { t } = useTranslation("common", { i18n: i18n });
  //Headers needed for the the .csv exporter
  const headers = [
    { label: "Email", key: "email" },
    { label: "First name", key: "first_name" },
    { label: "Last name", key: "last_name" },
    { label: "kWh", key: "kWh" },
    { label: "€", key: "total_cost" },
  ];

  /**
   * Rows that contain a header, and a Tabs component for the two different tabs. The two tabs contain the
   * ReportingCompletedTransactions component and ActiveTransactions component respectively.
   */

  return (
    <>
      {/*Row for the header of the page*/}
      <Row>
        <Col>
          <h2>{t("components.reporting.header")}</h2>
        </Col>
      </Row>
      {/*Row containing the Tabs component responsible for creating the two tabs needed for this page*/}
      <Row>
        {/*Tabs component that defaults to the "Completed transactions" tab when the user access this page*/}
        <Tabs defaultActiveKey="completed" id="" className="mb-3">
          {/*Tab for the completed transactions*/}
          <Tab eventKey="completed" title={t("components.reporting.tabs.completed.header")}>
            <Row>
              <ReportingCompletedTransactions
                areas={areas}
                areaField={areaField}
                setAreaField={setAreaField}
                startDate={startDate}
                setStartDate={setStartDate}
                stopDate={stopDate}
                setStopDate={setStopDate}
                invalidDates={invalidDates}
                searchComplete={searchComplete}
                searching={searching}
                searchBasic={searchBasic}
                search={search}
                rangeAreaName={rangeAreaName}
                rangeText={rangeText}
                allTransactions={allTransactions}
                displayTransactions={displayTransactions}
                headers={headers}
                selectedTransactions={selectedTransactions}
                handleSetSelectedTransactions={handleSetSelectedTransactions}
                createInvoices={createInvoices}
                noRowChosen={noRowChosen}
                createInvoiceFail={createInvoiceFail}
                createInvoiceSuccess={createInvoiceSuccess}
                user={user}
                notReportingSite={notReportingSite}
                setSelectedInvoiceMethod={setSelectedInvoiceMethod}
                setInvoicingFilter={setInvoicingFilter}
                selectedInvoiceMethod={selectedInvoiceMethod}
                invoicingFilter={invoicingFilter}
                currArea={currArea}
                setCurrArea={setCurrArea}
                checkCards={checkCards}
                cardsSuccess={cardsSuccess}
                cardsFail={cardsFail}
              />
            </Row>
          </Tab>
          {/*Tab for the active transactions*/}

          {user.user_level > 1 && (
            <Tab eventKey="invoice" title={t("components.reporting.tabs.invoices.header")}>
              <Row>
                <ReportingInvoices
                  invoices={invoices}
                  setSelectedInvoices={setSelectedInvoices}
                  user={user}
                  sendToPayTrail={sendToPayTrail}
                  sendToPaytrailHistory={sendToPaytrailHistory}
                />
              </Row>
            </Tab>
          )}
        </Tabs>
      </Row>
    </>
  );
};
