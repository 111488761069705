import { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { StyledLandingPage } from "./LandingPage.styled";
import { getMyTransactionsActive } from "../../../services/myTransactionsService";
import { remoteStop } from "../../../utils/remoteStop";
import ActiveTransactions from "../Transactions/ActiveTransactions";
import { refreshActiveTransactions } from "../../../utils/refreshActiveTransactions";
import { getUserTags } from "../../../services/userService";
import { getChargingAccessReporting } from "../../../services/chargepointService";
import { logger } from "../../../utils/logger";
import { getPaymentMethodStatus } from "../../../services/userService";
import { cardsExpiring, defaultCardExpired } from "../../../services/paytrailService";
import { addDefaultTag } from "./LandingPageUtils";
import User from "../../../model/Classes/User";
import { ActiveTransaction } from "../../../model/Classes/Transaction";
import { Tag } from "../../../model/Classes/Tag";
import i18N from "../../../i18n";
import { useTranslation } from "react-i18next";
import { MaintenanceNotification } from "../../GeneralComponents/MaintenanceNotification";
import NotificationButton from "../../GeneralComponents/NotificationButton";

/**
 * Component responsible for displaying the landing page. The component contains the following:
 * - Wattery logo
 * - Greeting message to the user
 * - Button for starting a new transaction (redirects to the StartNewTransaction page)
 * - If the user doesn't have any active tags, then a button for adding a new tag
 * - A header + refresh button + table for all the active transactions. If none exist, just a header notifying the user that they do not have any active transactions
 * @param {*} user state containing the user of the application
 * @param {*} activeTransactions state containing all the active transactions that belong to the user
 * @param {*} history history object
 * @param {*} showLoading state for showing the loading animation
 * @param {*} showCheckmark state for showing the checkmark when the loading is complete
 * @param {*} refresh helper function for refreshing all active transactions
 * @param {*} callForceStop helper function for calling the forceStop utility function
 * @param {*} callRemoteStop helper function for calling the remoteStop utility function
 * @param {*} forceStopSuccess state for showing a success alert when force stopping
 * @param {*} forceStopError state for showing a failure alert when force stopping
 * @param {*} forceStopSuccessButRefreshActive state for showing an alert when force stopping was successful, but refreshing failed
 * @param {*} remoteStopSuccess state for showing a success alert when remote stopping
 * @param {*} remoteStopError state for showing a failure alert when remote stopping
 * @param {*} remoteStopSuccessButRefreshActive state for showing an alert when remote stopping was successful, but refreshing failed
 * @param {*} hasActiveTags state for knowing if the user has any tags
 * @returns landing page view
 */

declare interface LandingPageViewProps {
  user: User;
  activeTransactions: ActiveTransaction[];
  history: any;
  refresh: () => Promise<void>;
  callForceStop?: (id: number, area_id?: number) => Promise<void>;
  callRemoteStop: (id: number, cpId?: string) => Promise<void>;
  forceStopSuccess?: boolean;
  forceStopError?: boolean;
  forceStopSuccessButRefreshActive?: boolean;
  remoteStopSuccess: boolean;
  remoteStopError: boolean;
  remoteStopSuccessButRefreshActive: boolean;
  showLoading: boolean;
  showCheckmark: boolean;
  hasActiveTags?: boolean;
  noCardAdded: boolean;
  gracePeriod: boolean;
  cardIsExpiring: boolean;
  isBlocked: boolean;
  defaultExpired: boolean;
}
const LandingPageView = ({
  user,
  activeTransactions,
  history,
  showLoading,
  showCheckmark,
  refresh,
  callForceStop,
  callRemoteStop,
  forceStopSuccess,
  forceStopError,
  forceStopSuccessButRefreshActive,
  remoteStopSuccess,
  remoteStopError,
  remoteStopSuccessButRefreshActive,
  hasActiveTags,
  noCardAdded,
  gracePeriod,
  cardIsExpiring,
  isBlocked,
  defaultExpired,
}: LandingPageViewProps) => {
  const { t } = useTranslation("common", { i18n: i18N });
  const logo = "https://files.wattery.io/img/logo/SVG/Wattery_Logo_RGB_Green.svg";
  return (
    <>
      <Row>
        <img src={logo} alt="logo" className="logo"></img>
      </Row>
      <Row className="mb-3">
        <h1 style={{ textAlign: "center" }}>
          {t("components.landingPage.static.greeting")} {user.first_name}!
        </h1>
      </Row>
      <Row className="mb-3">
        <MaintenanceNotification />
      </Row>
      <NotificationButton
        noTooltip
        buttonText={t("components.landingPage.buttons.startTransaction.text")}
        clickFunc={() => history.push("/startNewTransaction")}
      />
      {noCardAdded && (
        <NotificationButton
          tooltipText={t("components.landingPage.buttons.addCard.tooltip")}
          buttonText={t("components.landingPage.buttons.addCard.text")}
          clickFunc={() =>
            history.push({
              pathname: "/settings",
              state: { fromLandingPageShowCards: true },
            })
          }
        />
      )}
      {gracePeriod && (
        <NotificationButton
          tooltipText={t("components.landingPage.buttons.invoicing.tooltip")}
          buttonText={t("components.landingPage.buttons.invoicing.text")}
          clickFunc={() =>
            history.push({
              pathname: "/settings",
              state: { fromLandingPageShowCards: true },
            })
          }
          variant="warning"
        />
      )}
      {isBlocked && (
        <NotificationButton
          tooltipText={t("components.landingPage.buttons.blocked.tooltip")}
          buttonText={t("components.landingPage.buttons.blocked.text")}
          clickFunc={() =>
            history.push({
              pathname: "/settings",
              state: { fromLandingPageShowCards: true },
            })
          }
          variant="danger"
        />
      )}
      {cardIsExpiring && (
        <NotificationButton
          tooltipText={t("components.landingPage.buttons.expiring.tooltip")}
          buttonText={t("components.landingPage.buttons.expiring.text")}
          variant="warning"
          clickFunc={() =>
            history.push({
              pathname: "/settings",
              state: { fromLandingPageShowCards: true },
            })
          }
        />
      )}
      {defaultExpired && (
        <NotificationButton
          tooltipText={t("components.landingPage.buttons.expired.tooltip")}
          buttonText={t("components.landingPage.buttons.expired.text")}
          variant="warning"
          clickFunc={() =>
            history.push({
              pathname: "/settings",
              state: { fromLandingPageShowCards: true },
            })
          }
        />
      )}
      {/*Row containing the active transactions*/}
      <Row className="mb-3">
        <ActiveTransactions
          forceStopSuccess={forceStopSuccess!}
          forceStopError={forceStopError!}
          forceStopSuccessButRefreshActive={forceStopSuccessButRefreshActive!}
          remoteStopSuccess={remoteStopSuccess}
          remoteStopError={remoteStopError}
          remoteStopSuccessButRefreshActive={remoteStopSuccessButRefreshActive}
          activeTransactions={activeTransactions}
          showCheckmark={showCheckmark}
          showLoading={showLoading}
          refresh={refresh}
          callRemoteStop={callRemoteStop}
          callForceStop={callForceStop}
          forLandingPage={true}
          user={user}
          forChargepoint={false}
          forMyTransactions={false}
        />
      </Row>
    </>
  );
};

declare interface LandingPageProps {
  user: User;
  history: any;
}

const LandingPage = ({ user, history }: LandingPageProps) => {
  const [activeTransactions, setActiveTransactions] = useState<ActiveTransaction[]>([]); //state for all the active transactions belonging to the user
  const [hasActiveTags, setHasActiveTags] = useState(true); //state for knowing if the user owns any tags
  const [noCardAdded, setNoCardAdded] = useState(false); //state for knowing if the user hasn't added a card and has access to a Wattery-invoiced area
  const [cardIsExpiring, setCardIsExpiring] = useState(false); //state for knowing if the user has a default card that is expiring soon
  const [defaultExpired, setDefaultExpired] = useState(false);
  const [gracePeriod, setGracePeriod] = useState(false); //state for knowing if the user has an active grace period ongoing
  const [isBlocked, setIsBlocked] = useState(false); //state for knowing if the user is blocked
  const [showLoading, setShowLoading] = useState(false); //state for showing the loading animation (used when refreshing active transactions)
  const [showCheckmark, setShowCheckmark] = useState(false); //state for showing a checkmark (used when refreshing active transactions)
  const [showView, setShowView] = useState(-1); //state for showing the loading screen, error screen (not ever shown at the moment), or landing page screen

  const [remoteStopSuccess, setRemoteStopSuccess] = useState(false); //state for succesfully remote stopping an active transaction alert
  const [remoteStopError, setRemoteStopError] = useState(false); //state for failed remote stopping of an active transaction alert
  const [remoteStopSuccessButRefreshActive, setRemoteStopSuccessButRefreshActive] = useState(false); //state for succesfully remote stopping an active transaction, but failed refreshing alert

  //NOTE! These alerts are commented away, since we don't allow force stopping an active transaction from the landing page.
  //We might add that feature later on, hence why it's commented and not removed.

  //const [forceStopSuccess, setForceStopSuccess] = useState(false)
  //const [forceStopError, setForceStopError] = useState(false)
  //const [forceStopSuccessButRefreshActive, setForceStopSuccessButRefreshActive] = useState(false)

  /**
   * Upon initialization, fetch the active transactions for the user. Afterwards, set the showView state
   * to 1 to show the landing page to the user. The reason why the code doesn't set the showView state
   * to 0 notifying the user that an error occured when fetching the active transactions is due to the
   * fact that we still want to show the landing page upon that kind of an error. It would otherwise be
   * really weird for the user to instantly see an error message when they log in...
   */
  useEffect(() => {
    /**
     * Asynchronous helper function for calling the getMyTransactionsActive() and getOwnTags service functions
     * for fetching the active transactions and tags belonging to the user from the backend. If it's
     * successful, it updates the activeTransactions and hasActiveTags state with the data. If it fails, it
     * simply logs the error and doesn't update either state.
     */
    const getData = async () => {
      //Get the active transactions and tags belonging to the user from the backend
      const tagResponse = await getUserTags();
      if (tagResponse.data.filter((tag: Tag) => tag.disabled === 0).length === 0) setHasActiveTags(false);
      if (tagResponse.data.filter((tag: Tag) => tag.nickname === "App").length === 0) {
        await addDefaultTag();
      }
      const activeTransactionsResponse = await getMyTransactionsActive();

      //Check if it's successful
      if (activeTransactionsResponse.success && tagResponse.success) {
        //Successfully fetched the data, update the activeTransactions and hasActiveTags state with the data
        setActiveTransactions(activeTransactionsResponse.data);
      } else {
        //Some errors occured

        if (!activeTransactionsResponse.success) {
          logger(activeTransactionsResponse.data);
        }

        if (!tagResponse.success) {
          logger(tagResponse.data);
        }
      }
    };
    //Call the helper function getData() and set the showView state to 1
    if (process.env.REACT_APP_MODE !== "TEST") getData();
    setShowView(1);
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const statusRes = await getPaymentMethodStatus();
        if (statusRes.success && statusRes.data[0]?.payment_method_status) {
          const status = statusRes[0].payment_method_status;
          switch (status) {
            case 0:
              const reportingRes = await getChargingAccessReporting();
              if (reportingRes.success) {
                const repCon = reportingRes.data.length > 0;
                if (!repCon) {
                  return setNoCardAdded(true);
                }
                return;
              }
              return setNoCardAdded(true);
            case 2:
              setGracePeriod(true);
              break;
            case 3:
              setIsBlocked(true);
              break;
            default:
              break;
          }
        }
      } catch (e) {
        logger(e);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await cardsExpiring();
        const res2 = await defaultCardExpired();
        if (res.data.length > 0) setCardIsExpiring(true);
        if (res2.data.length > 0) setDefaultExpired(true);
      } catch (e) {
        logger(e);
      }
    };

    getData();
  }, []);

  /**
   * Asynchronous helper function for refreshing all the active transactions (refresh button).
   * The function calls the utility function refreshActiveTransactions which handles the
   * functionality for refreshing active transactions.
   */
  const refresh = async () => {
    //Call the utility function refresActiveTransactions.
    //We pass the string "landingPage" as the first parameter to indicate that we're calling this
    //function for the LandingPage component. Additionally, we pass null as the second and third
    //parameter since we don't need to specify an area id nor a chargepoint id
    await refreshActiveTransactions("landingPage", null, null, setActiveTransactions, setShowLoading, setShowCheckmark);
  };

  /**
   * NOTE! This code here is commented since we don't allow force stopping an active transaction from
   * the landing page. We might add that feature later on, hence why it's commented and not removed.
   */

  /*const callForceStop = async (transactionId, areaId) => {
        const data = {
            transaction_id: transactionId,
            area_id: areaId
        }
        const res = await sendForceStop(data)
        if(res[0]){
            try {
                const activeTransactionsResponse = await getMyTransactionsActive()
                setActiveTransactions(activeTransactionsResponse[1])
                timer(setForceStopSuccess)
            } catch (e) {
                logger(e)
                timer(setForceStopSuccessButRefreshActive)
            }
        } else {
            logger(res[1]);
            timer(setForceStopError)
        }
    }*/

  /**
   * Asynchronous helper function used to call the remoteStop utility function when the user
   * wants to remote stop an active transaction.
   * @param {*} activeId the id of the active transaction
   * @param {*} cpId the id of the chargepoint the transaction belongs to
   */
  const callRemoteStop = async (activeId: number, cpId?: string) => {
    //Call the remoteStop utility function
    //NOTE! We pass null as the third parameter since it's only needed when the function
    //is called from the Reporting component.
    await remoteStop(
      activeId,
      "landingPage",
      setRemoteStopSuccess,
      setRemoteStopError,
      setRemoteStopSuccessButRefreshActive,
      setActiveTransactions,
      undefined,
      cpId
    );
  };

  /**
   * Initially, the component shows a loading screen. When the showView state changes to any value
   * expect -1 and 0, it shows the LandingPageView component.
   *
   * NOTE! If the showView state is 0, we show an alert to the user notifying them that an error
   * occured and to refresh the page, but at the moment the showView state is never set to 0.
   * This is still left here since this might be added later in the future
   */
  const { t } = useTranslation("common", { i18n: i18N });
  return (
    <StyledLandingPage className="top-level-component">
      {showView === -1 ? (
        <>
          <h2 className="align-self-center">{t("global.view.loading")}</h2>
        </>
      ) : showView === 0 ? (
        <h2 className="align-self-center">{t("global.view.error")}</h2>
      ) : (
        <>
          <Container id="component-margin" className="container-min-height-min-content">
            <LandingPageView
              user={user}
              activeTransactions={activeTransactions}
              showLoading={showLoading}
              showCheckmark={showCheckmark}
              refresh={refresh}
              history={history}
              callRemoteStop={callRemoteStop}
              remoteStopSuccess={remoteStopSuccess}
              remoteStopError={remoteStopError}
              remoteStopSuccessButRefreshActive={remoteStopSuccessButRefreshActive}
              hasActiveTags={hasActiveTags}
              noCardAdded={noCardAdded}
              gracePeriod={gracePeriod}
              cardIsExpiring={cardIsExpiring}
              isBlocked={isBlocked}
              defaultExpired={defaultExpired}
            />
          </Container>
        </>
      )}
    </StyledLandingPage>
  );
};

export default LandingPage;
